.categories-carousel {
  display: flex;
  align-items: center;
  width: 80%;
  margin: auto;
}

.categories-carousel-content {
  display: flex;
  overflow: hidden;
}

.categories-carousel-item {
  flex: 0 0 11.1%;

  /* Display one category at a time */
  transition: transform 0.5s ease-in-out;
  white-space: nowrap;
}

.active {
  transform: translateX(0);
}

button {
  font-size: 1.5rem;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.FrontMain_Stores,
.upcoming-appointments-container {
  .sliderhere {
    .slick-next {
      width: 80px;
      height: 100px;
      border: none;
      z-index: 1000;
      right: -43px;
      box-shadow: none;
      background: url("https://res.cloudinary.com/drt6vurtt/image/upload/v1699516445/Group_30600_czlu3e.svg")
        center center no-repeat !important;
      &::before {
        display: none;
      }
    }
    .slick-prev {
      width: 80px;
      height: 100px;
      z-index: 1000;
      left: -43px;
      border: none;
      box-shadow: none;
      background: url("https://res.cloudinary.com/drt6vurtt/image/upload/v1699516445/Group_30850_u49fv1.svg")
        center center no-repeat !important;
      &::before {
        display: none;
      }
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 57px !important;
    }
    .slick-list {
      padding-top: 24px;
      padding-bottom: 18px;
    }

    .outer {
      .cardmain {
        cursor: pointer;

        .upperheading {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .ratings {
          i {
            color: #cecece !important;
          }
          .rating-color {
            color: #ffa417 !important;
          }
        }

        position: relative;
        height: 345px !important;
        border-radius: 17px;
        margin-right: 20px;
        background: #fff;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

        .uppercard {
          height: 222px;
          position: relative;

          .rectangle_shape {
            position: absolute;
            bottom: 0px;
            width: 100%;
            // height: 35px;
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: rgb(0 0 0 / 70%);
            // opacity: 0.5;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .upperheading {
              h3 {
                color: #fff;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.12px;
                padding-right: 15px;
              }
            }

            .rirr {
              h5 {
                color: #fff;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
                padding-left: 15px;
              }
            }
          }
          .cnctwltimg {
            height: 220px;
            width: 100%;
            object-fit: cover;
            border-radius: 17px 17px 0px 0px;
          }
          .mmmmmmm {
            position: absolute;
            border-radius: 17px 17px 0px 0px;
            top: 0;
            height: 104px;
            width: 100%;
            object-fit: cover;
          }
        }

        .lowercard {
          padding: 16px;
          padding-bottom: 20px;

          .slotshere {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            .design {
              cursor: pointer;
              border-radius: 8px;
              border: 1px solid rgba(119, 56, 162, 0.6);
              background: #edf0ff;
              width: 80px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                background-color: #4b2763;

                h5 {
                  color: #fff;
                }
              }

              h5 {
                color: #4b2763;
                text-align: center;
                font-family: "Lexend";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
          h4 {
            color: #2e2b2b;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            // width: 158px;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
            cursor: pointer;
            padding-bottom: 25px;
          }
          h3 {
            color: #000;
            font-family: "Lexend";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 1px;
          }
          .outrermainmain {
            padding-bottom: 15px;
            margin-bottom: 19px;
            border-bottom: 2px dotted rgba(182, 182, 182, 0.4588235294);
            margin-top: 8px;
            h6 {
              color: rgba(0, 0, 0, 0.6);
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              margin-top: 12px;
            }
            .main-outer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .rightside {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .ppppp {
                  width: 6.87px;
                  height: 6px;
                  border-radius: 50%;
                  background-color: #2e2b2b;
                  margin-right: 9px;
                }
                .rirr {
                  h5 {
                    color: #707070;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                  }
                }
              }
              .left-side {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .text {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 3px;
                  margin-left: 5px;
                  h4 {
                    color: rgba(0, 0, 0, 0.6) !important;
                    font-family: "Poppins" !important;
                    font-size: 12px !important;
                    font-style: normal !important;
                    font-weight: 400 !important;
                    line-height: 17px !important;
                    padding-bottom: 0px !important;
                  }
                  h5 {
                    color: rgba(0, 0, 0, 0.6);
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.FrontMain_Stores {
  margin-top: 40px;
  margin-bottom: 40px;

  .caresousaldownbaloon {
    border-radius: 17px;
    height: 178px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
    padding: 26px;
    padding-left: 18px;
    padding-top: 22px;
    padding-bottom: 22px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 58px;
    margin-top: -3px;
    border: 2px solid #f1f1f1;
    transition: 0.3s linear;
    margin-bottom: 14px;

    &.show {
      height: unset;
      flex-wrap: wrap;
      padding: 22px 26px;
    }

    .innerprofile {
      width: 85px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 132px;
      overflow: visible;
      opacity: 1;
      transition: 0.4s linear;
      // position: relative;

      .upperprofile {
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;

        .calenderimg {
          position: absolute;
          right: -5px;
          top: -3px;
          pointer-events: fill !important;
        }

        .profileimg {
          width: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 50%;
        }

        .rightsidem {
          position: absolute;
          bottom: 0px;
          left: -1px;
          border-radius: 12.5px;
          background: #fff;
          height: 13px;
          width: 28px;
          border-radius: 6.561px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;

          .upperright {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h4 {
              color: rgba(0, 0, 0, 0.6) !important;
              font-family: "Poppins" !important;
              font-size: 9px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 17px !important;
              padding-bottom: 0px !important;
              margin-top: -2px !important;
            }

            h5 {
              color: rgba(0, 0, 0, 0.6);
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 17px;
            }
          }

          .ratings {
            margin-right: 10px;
          }

          .ratings i {
            margin-top: -2px !important;
            color: #cecece;
            font-size: 32px;
          }

          .rating-color {
            color: #fbc634 !important;
          }

          .review-count {
            font-weight: 400;
            margin-bottom: 2px;
            font-size: 24px !important;
          }

          .small-ratings i {
            color: #cecece;
          }

          .review-stat {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }

      .lowerside {
        .maintool {
          position: relative;
          cursor: pointer;
          &:hover .tooltiptext {
            display: block;
          }

          .tooltiptext {
            font-family: "Poppins";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            padding: 2px 8px;
            border-radius: 4px;
            position: absolute;
            top: -23px;
            right: -26px;
            background: #000;
            color: white;
            display: none;
          }
        }

        .outmainh5 {
          cursor: pointer;
          position: relative;

          &:hover .mainouterlocation {
            display: flex !important;
          }
        }

        .mainouterlocation {
          position: absolute;
          bottom: -72px;
          border-radius: 3px;
          background: #fff;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 8px;
          width: 220px;
          display: none;
          z-index: 1111111;

          img {
            width: 14.4px;
            height: 14.4px;
            margin-top: 1px;
          }

          .text {
            margin-left: 5px;
          }

          p {
            color: rgba(3, 3, 3, 0.7);
            font-family: "Poppins";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: start !important;
          }

          h6 {
            color: rgba(3, 3, 3, 0.7);
            font-family: "Poppins";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            text-align: start;
            line-height: normal;
          }
        }

        h4 {
          color: #000;
          font-family: "Lexend";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-top: 8px;
        }

        h5 {
          color: rgba(3, 3, 3, 0.7);
          text-align: center;
          font-family: "Lexend";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-decoration-line: underline;
        }

        button {
          border-radius: 5px;
          border: 1px solid rgba(119, 56, 162, 0.6);
          background: #edf0ff;
          margin-top: 9px;
          color: #4b2763;
          text-align: center;
          font-family: "Lexend";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          height: 25px;
          outline: none;
          box-shadow: none;
          width: 70px;

          &:hover {
            background-color: #4b2763;
            color: #fff;
          }
        }
      }
    }
  }

  .caresousaldownbaloon.close {
    height: 0px;
    padding: 0px;
    border: none;

    .innerprofile {
      height: 0px;
      opacity: 0;
      overflow: hidden;
    }
  }

  .owl-theme .owl-nav .disabled {
    display: "none";
  }

  .owl-carousel .owl-nav {
    position: absolute;
    top: -75px;
    right: 0;
  }

  .owl-carousel .owl-nav button.owl-prev {
    display: none;
  }

  .owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 200px;
    right: -50px;
    opacity: 1;

    &:hover {
      background-color: transparent;
    }
  }

  .main_upper_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftside {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .anme {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h4 {
          color: #000;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-right: 3.1px;
        }
      }

      .beatury-outer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h4 {
          margin-right: 6.34px;
          margin-left: 13px;
          color: #7738a2;
          text-align: center;
          font-family: "Lexend";
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 2px;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background: #edf0ff;
          padding: 4px 10px;
        }

        h5 {
          color: #236512;
          text-align: center;
          font-family: "Lexend";
          font-size: 9px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 2px;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          background: #edfff0;
          padding: 4px 10px;
        }
      }
    }

    .rightside {
      a {
        color: #4b2763;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }
  }

  .owl-main-css {
    .cardmain {
      cursor: pointer;
      position: relative;
      height: 345px !important;
      border-radius: 17px;
      background: #fff;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

      .uppercard {
        height: 220px;
        position: relative;

        h3 {
          color: #fff;
          font-family: "Lexend";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 1px;
          top: 30px;
          right: 31px;
          position: absolute;
        }

        h4 {
          color: rgba(255, 255, 255, 0.6);
          font-family: "Lexend";
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
          text-decoration-line: strikethrough;
          top: 56px;
          right: 45px;
          position: absolute;
        }

        .cnctwltimg {
          height: 222px;
          width: 100%;
          object-fit: cover;
          border-radius: 17px 17px 0px 0px;
        }

        .mmmmmmm {
          position: absolute;
          border-radius: 17px 17px 0px 0px;
          top: 0;
          height: 104px;
          width: 100%;
          object-fit: cover;
        }
      }

      .lowercard {
        padding: 16px;
        padding-bottom: 20px;

        .slotshere {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .design {
            border-radius: 8px;
            border: 1px solid rgba(119, 56, 162, 0.6);
            background: #edf0ff;
            padding: 10px 16px;

            &:hover {
              background-color: #4b2763;

              h5 {
                color: #fff;
              }
            }

            h5 {
              color: #4b2763;
              text-align: center;
              font-family: "Lexend";
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        h4 {
          color: #2e2b2b;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          padding-bottom: 3px;
        }

        .outrermainmain {
          padding-bottom: 14px;
          margin-bottom: 14px;
          border-bottom: 2px dotted #b6b6b675;

          h6 {
            color: rgba(0, 0, 0, 0.6);
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 12px;
          }

          .main-outer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .rightside {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 4px;

              .ppppp {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #2e2b2b;
                margin-right: 9px;
              }

              .rirr {
                h5 {
                  color: #707070;
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  letter-spacing: 0.12px;
                }
              }
            }

            .left-side {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .text {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                h4 {
                  color: rgba(0, 0, 0, 0.6) !important;
                  font-family: "Poppins" !important;
                  font-size: 12px !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  line-height: 17px !important;
                  padding-bottom: 0px !important;
                }

                h5 {
                  color: rgba(0, 0, 0, 0.6);
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 17px;
                }
              }
            }
          }
        }
      }
    }

    .ratings {
      margin-right: 10px;
    }

    .ratings i {
      color: #cecece;
      font-size: 32px;
    }

    .rating-color {
      color: #fbc634 !important;
    }

    .review-count {
      font-weight: 400;
      margin-bottom: 2px;
      font-size: 24px !important;
    }

    .small-ratings i {
      color: #cecece;
    }

    .review-stat {
      font-weight: 300;
      font-size: 18px;
      margin-bottom: 2px;
    }
  }

  .owl-carousel .owl-stage {
    padding-top: 24px;
    padding-bottom: 20px;
  }
}

.FrontMain_Stores
  .caresousaldownbaloon
  .innerprofile
  .upperprofile
  .rightsidem
  .ratings
  i {
  margin-right: 5.5px;
  font-size: 6px !important;
}

.FrontMain_Stores
  .caresousaldownbaloon
  .innerprofile
  .upperprofile
  .rightsidem
  .rating-color {
  color: #ffa417 !important;
}

.FrontMain_Stores
  .caresousaldownbaloon
  .innerprofile
  .upperprofile
  .rightsidem
  .ratings {
  margin-right: -4px;
  margin-top: -14px;
}

.FrontMain_Stores .owl-main-css .ratings i {
  margin-right: 2px;
  font-size: 15px !important;
}

.FrontMain_Stores .owl-main-css .rating-color {
  color: #ffa417 !important;
}

.FrontMain_Stores .owl-main-css .ratings {
  margin-right: 4px;
}

.FrontMain_Stores .owl-main-css .cardmain .lowercard .slotshere .design {
  cursor: pointer;

  &:hover {
    background: #4b2763;

    h5 {
      color: #fff;
    }
  }
}

.t-d-n {
  text-decoration: none !important;
}

.incart {
  background-color: #236512 !important;
  color: #cecece !important;
  pointer-events: none;
}

@media (max-width: 1680px) {
  .FrontMain_Stores .sliderhere .cardmain .lowercard .slotshere .design {
    // padding: 10px 13px;
  }

  .sliderhere .outer .cardmain .lowercard .outrermainmain {
    margin-bottom: 16px !important;
  }
}

@media (max-width: 1500px) {
  .FrontMain_Stores .sliderhere .cardmain .lowercard h4 {
    font-size: 14px;
    padding-bottom: 0px;
  }

  .FrontMain_Stores .sliderhere .cardmain .lowercard {
    padding-top: 10px;
  }

  .FrontMain_Stores .sliderhere .ratings i {
    font-size: 12px !important;
  }

  .FrontMain_Stores
    .sliderhere
    .cardmain
    .lowercard
    .outrermainmain
    .main-outer
    .left-side
    .text
    h4 {
    font-size: 10px !important;
    margin-top: 4px;
  }

  .FrontMain_Stores
    .sliderhere
    .cardmain
    .lowercard
    .outrermainmain
    .main-outer
    .left-side
    .text
    h5 {
    font-size: 10px !important;
    margin-top: 4px;
  }

  .FrontMain_Stores
    .sliderhere
    .cardmain
    .lowercard
    .outrermainmain
    .main-outer
    .rightside
    .rirr
    h5 {
    font-size: 10px !important;
  }

  .FrontMain_Stores
    .sliderhere
    .cardmain
    .lowercard
    .outrermainmain
    .main-outer
    .rightside {
    margin-top: 5px;
  }

  .FrontMain_Stores
    .sliderhere
    .cardmain
    .lowercard
    .outrermainmain
    .main-outer {
    margin-top: -3px;
    margin-bottom: 8px;
  }

  .FrontMain_Stores .sliderhere .cardmain .lowercard .outrermainmain h6 {
    font-size: 10px !important;
    margin-top: 4px;
  }

  .FrontMain_Stores .sliderhere .outer .cardmain .lowercard h3 {
    font-size: 16px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain .lowercard .outrermainmain {
    padding-bottom: 10px !important;
    margin-bottom: 18px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain .lowercard .slotshere .design {
    // padding: 8px 10px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain .lowercard .slotshere .design h5 {
    font-size: 12px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain .uppercard h3 {
    font-size: 18px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain .uppercard h4 {
    font-size: 14px !important;
  }

  .FrontMain_Stores .sliderhere .cardmain {
    height: 260px !important;
  }

  .FrontMain_Stores .main_upper_bar .leftside .anme h4 {
    font-size: 18px !important;
  }

  .FrontMain_Stores .owl-carousel .owl-stage {
    padding-top: 18px !important;
  }

  .FrontMain_Stores .owl-carousel .owl-nav button.owl-next {
    top: 182px;
    right: -45px;
  }

  .FrontMain_Stores .owl-carousel .owl-nav button.owl-next {
    img {
      width: 80px;
    }
  }
}

@media (max-width: 1400px) {
  .FrontMain_Stores .sliderhere .cardmain .lowercard .slotshere .design {
    // padding: 8px 10px !important;
  }
}

@media (max-width: 1300px) {
  .FrontMain_Stores .sliderhere .cardmain .lowercard .slotshere .design {
    // padding: 7px 8px !important;
  }
}

.rectangle_shape {
  h4 {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.sliderhere .outer .cardmain .upperheading {
  margin-bottom: 31px;
  margin-top: 8px;
}

.sliderhere .outer .cardmain .lowercard h3 {
  font-size: 18px !important;
  font-weight: 600;
}

.rirr {
  h5 {
    color: #000;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1px;
    font-size: 18px;
  }
}

.rectangle_shape h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

img {
  pointer-events: none !important;
}

.FrontMain_Stores .caresousaldownbaloon .innerprofile .lowerside h4 {
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loaderStart {
  position: fixed;
  background: rgb(0 0 0 / 90%);
  bottom: 0;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addtocart {
  width: 100%;
  button {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgba(119, 56, 162, 0.6);
    background: #edf0ff;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4b2763;
    text-align: center;
    font-family: "Lexend";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:hover {
      background-color: #4b2763;
      color: #fff;
    }
  }
}
