.bookingmain {
    margin-bottom: 100px;

    .form-check-input:checked {
        background-color: #4B2763;
        border-color: #4B2763;
    }

    .e-schedule {
        border-radius: 10px !important;
        box-shadow: 0px 2px 10px 3px rgba(218, 218, 218, 0.50) !important;
    }

    .bookingtwo {
        margin-top: 100px;

        .middivds {
            border-radius: 20px;
            border: 1px solid #C7C7C7;
            background: #FFF;
            padding: 19px;
            margin-top: -33px;

            .outersidesid {
                .indderside {
                    .lolololol {
                        margin-top: 22px;
                        margin-bottom: 22px;
                    }

                    h4 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    h3 {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .o {
                        color: #787878;
                        font-family: Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .oo {
                        color: #e1e1e1;
                        font-size: 25px;
                    }

                    .ooo {
                        color: #000;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .i {
                        color: #4B2763;
                        font-family: Poppins;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    .ii {
                        color: #e1e1e1;
                        font-size: 25px;
                    }

                    .iii {
                        color: #4B2763;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }

            h2 {
                color: #4B2763;
                padding-bottom: 40px;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

        }

        .jujujuju {
            h2 {
                padding-bottom: 13px !important;
            }
        }

        .leftsidee {
            width: 170px;

        }
    }

    .downdivservice {
        margin-top: 58px;

        .service_detsilcheck {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .checkboxxes {
                display: flex;
                justify-self: center;
                align-items: center;
                margin-left: 79px;
                margin-right: 120px;

                .form-check-input:focus {
                    box-shadow: none !important;
                }

                .form-check-input[type=checkbox] {
                    border: none !important;
                    border-radius: 4px;
                    background-color: #D9D9D9;
                    width: 20px;
                    height: 20px;
                }

                .form-check-input:checked {
                    background-color: #4B2763;
                }

                label {
                    color: #808285;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-left: 10px;
                }

                .mmmmlllmmll {
                    margin-right: 80px;
                }
            }

            .buttonall {
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    background-color: transparent;
                    outline: none;
                    border: none;
                    padding: 0px !important;
                }

                .iiiooiioo {
                    margin-right: 35px;
                    margin-left: 35px;
                }
            }
        }

        h2 {
            color: #000;
            font-family: 'Poppins';
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .outerinner {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 35px;

            .outmainleft_sidecheck {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .form-check-input:focus {
                    box-shadow: none !important;
                }

                .form-check-input[type=checkbox] {
                    border: none !important;
                    border-radius: 4px;
                    background-color: #D9D9D9;
                    width: 22.438px;
                    height: 22.438px;
                }

                .form-check-input:checked {
                    background-color: #4B2763;
                }
            }

            .leftsideinnerdetail {
                // border-left: 3px solid #4B2763;
                height: 164px;
                margin-right: 40px;
                padding-left: 20px;

                .datehi {
                    margin-bottom: 15px;

                    h3 {
                        color: #000;
                        font-family: 'Lexend';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    h5 {
                        color: #000;
                        font-family: 'Lexend';
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin-bottom: 2px;
                    }
                }

                .timess {
                    h6 {
                        color: #707070;
                        font-family: 'Poppins';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 2px;
                        margin-top: 2px;
                    }

                    .leftborder {
                        border-left: 1px solid #707070;
                        height: 25px;
                    }
                }
            }

            .rightsideinnerdetail {
                position: relative;

                .mainrightsideborder {
                    position: absolute;
                    right: 0px;
                    width: 2px;
                    background-color: #D0B246;
                    height: 101px;
                    right: 0%;
                    transform: translate(-50%, 0);
                }

                // margin-left: 30px;
                background-color: #FFF;
                padding-left: 40px;
                padding-top: 28px;
                padding-right:46px;
                border-radius: 12px;
                padding-bottom: 23px;
                // border-right: 10px solid #D0B246;

                .maindiv {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .lefiside {
                        margin-right: 95px;

                        h3 {
                            color: #000;
                            font-family: 'Poppins';
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }

                        h4 {
                            color: #4B2763;
                            font-family: 'Poppins';
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding-top: 8px;
                            padding-bottom: 12px;
                        }

                        // h5 {
                        //     color: #D0B246;
                        //     font-family: 'Poppins';
                        //     font-size: 18px;
                        //     font-style: normal;
                        //     font-weight: 700;
                        //     line-height: normal;
                        // }
                    }

                    .buttoninner {
                        button {
                            outline: none;
                            border: none;
                            box-shadow: none;
                            background: transparent;
                            padding: 0px !important;
                        }

                        .iiiooiioo {
                            margin-left: 34px;
                            margin-right: 34px;
                        }
                    }

                    .rigiside {
                        margin-top: 12px;
                        margin-right: 80px;

                        h6 {
                            color: #707070;
                            font-family: 'Poppins';
                            font-size: 17px;
                            font-style: normal;
                            font-weight: 400;
                        }

                        h5 {
                            color: #D08846;
                            font-family: 'Poppins';
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-top: 12px;
                        }

                        // .buttons {
                        //     padding-bottom: 15px;

                        //     .jj {
                        //         border-radius: 8px;
                        //         background: #4B2763;
                        //         width: 140px;
                        //         height: 41px;
                        //         box-shadow: none;
                        //         border: none;
                        //         outline: none;
                        //         color: #FFF;
                        //         text-align: center;
                        //         font-family: 'Poppins';
                        //         font-size: 15px;
                        //         font-style: normal;
                        //         font-weight: 400;
                        //         line-height: 33px;
                        //         letter-spacing: 0.15px;
                        //         margin-right: 19px;
                        //     }

                        //     .kk {
                        //         border-radius: 8px;
                        //         background: #E5343D;
                        //         width: 140px;
                        //         height: 41px;
                        //         box-shadow: none;
                        //         border: none;
                        //         outline: none;
                        //         color: #FFF;
                        //         text-align: center;
                        //         font-family: 'Poppins';
                        //         font-size: 15px;
                        //         font-style: normal;
                        //         font-weight: 400;
                        //         line-height: 33px;
                        //         letter-spacing: 0.15px;
                        //     }
                        // }
                    }
                }
            }
        }
    }

    .llppllp {
        color: #4B2763 !important;
    }

    .detailbooking {
        .left {
            border-left: 4px solid rgba(75, 39, 99, 0.20);
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            padding-left: 23px;

            .innnerrr {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            h2 {
                color: #4B2763;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                margin-bottom: 24px;
                // line-height: normal;
            }

            .leftsidee {
                margin-right: 25px;

                h3 {
                    padding-bottom: 15px;
                    color: rgba(0, 0, 0, 0.60);
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    // line-height: 17px;
                }
            }

            .rightsidee {
                h3 {
                    color: #000;
                    padding-bottom: 12px;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }

    .schedule-control-section {
        .mainoutermaincalender {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .property-section {
                width: 280px;
                height: 649px;

                .accordion-item:first-of-type .accordion-button {
                    border-top-left-radius: 10px !important;
                    border-top-right-radius: 10px !important;
                    outline: none !important;
                    box-shadow: none !important;

                }

                .accordion-item:first-of-type {
                    border-top-left-radius: 10px !important;
                    border-top-right-radius: 10px !important;
                    border-left: none !important;
                    border-right: none !important;
                    border-top: none !important;
                }

                .accordion-item:last-of-type .accordion-button {
                    // border-top-left-radius: 10px!important;
                    // border-top-right-radius: 10px!important;
                    outline: none !important;
                    box-shadow: none !important;

                }

                .accordion-item:last-of-type {
                    border-left: none !important;
                    border-right: none !important;
                    border-bottom: none !important;
                }

                .accordion-button {
                    background-color: transparent !important;
                    color: #000 !important;
                    font-family: 'Poppins' !important;
                    font-size: 18px !important;
                    font-style: normal !important;
                    font-weight: 500 !important;
                    line-height: normal !important;
                }

                .accordion-button:not(.collapsed)::after {
                    background-image: url('https://res.cloudinary.com/drt6vurtt/image/upload/v1700121313/Icon_2_yqvvdo.svg') !important;
                    background-size: 15px !important;
                    margin-top: -10px !important;
                }

                .accordion-button::after {
                    background-image: url('https://res.cloudinary.com/drt6vurtt/image/upload/v1700121238/Icon_1_ueaj4e.svg') !important;
                    background-size: 11px !important;
                }

                .accordion-button:not(.collapsed) {
                    background-color: #F3F1F8 !important;
                }

                .speciallist {

                    // .accordion-item:first-of-type .accordion-button{
                    //     border-top-left-radius: 20px!important;
                    //     border-top-right-radius: 20px!important;
                    // }
                    // .accordion-item:first-of-type{
                    //     border-top-left-radius: 20px!important;
                    //     border-top-right-radius: 20px!important;
                    // }
                }
            }

            .control-section {
                width: 100%;
            }
        }

        .property-section {
            border-radius: 10px;
            border: 1px solid rgba(198, 195, 195, 0.50);
            background: #FFF;
            box-shadow: 0px 2px 10px 3px rgba(218, 218, 218, 0.50);

            // padding: 20px;
            .accordion {
                width: 100%;
            }

            .maindivss {
                display: flex;
                justify-content: space-between;
            }

            .dowcheck {
                .form-check {
                    margin-bottom: 18px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        padding-right: 11px;
                        padding-left: 15px;
                    }

                    label {
                        color: #000;
                        font-family: 'Poppins';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }
            }

            .leftsidespecial {
                .form-check-input:focus {
                    box-shadow: none !important;
                }

                .form-check-input[type=checkbox] {
                    border-color: rgb(221, 220, 220) !important;
                    // border-radius: 4px;
                    // background-color: #D9D9D9;
                    // width: 22.438px;
                    // height: 22.438px;
                }

                // border-right: 2px dotted rgba(0, 0, 0, 0.10);
            }

            // .rightsideservice {
            //     width: 60%;
            //     padding-left: 46px;
            // }

            .leftsidespecial {
                width: 100%;
            }

            h3 {
                color: #000;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 30px;
            }
        }

        .control-section {
            padding-left: 30px;
        }
    }

    .rightside {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .oo {
            width: 160px;
            height: 39.834px;
            color: #FFF;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
            box-shadow: none;
            border: none;
            margin-right: 15px;
        }

        .cancel {
            border-radius: 5px;
            background: #C82A2F;
        }

        .complete {
            border-radius: 5px;
            background: #34A853;
        }

        .mark {
            border-radius: 5px;
            background: #EA9E58;
        }

        .accept {
            border-radius: 5px;
            background: #EA9E58;
        }

        .oooo {
            margin-right: 20px;
            padding: 12px 20px;
            outline: none;
            border-radius: 6px;
            border: 1px solid #4B2763;
            background: #FFF;
            box-shadow: none;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;

            span {
                padding-left: 11px;
                color: #4B2763;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .pppp {
            background-color: transparent;
            outline: none;
            box-shadow: none;
            border: none;
        }
    }

    .yyyuuyy {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .texthere {
        margin-top: 58px;
        margin-bottom: 58px;

        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .lllpppllpp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        .rightside {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .oooo {
                margin-right: 20px;
                padding: 12px 20px;
                outline: none;
                border-radius: 6px;
                border: 1px solid #4B2763;
                background: #FFF;
                box-shadow: none;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;

                span {
                    padding-left: 11px;
                    color: #4B2763;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .pppp {
                background-color: transparent;
                outline: none;
                box-shadow: none;
                border: none;
            }
        }

        .nav-tabs .nav-link {
            background: #FFF;
            color: #ACACAC;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 8px 15px;
            border-radius: 0px;
        }

        .nav-tabs {
            border-bottom: none;
        }

        .nav-tabs .nav-link.active {
            background: rgba(75, 39, 99, 0.70);
            color: #FFF;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 0px;
            border: none;
        }
    }

    .tabsbookingliust {
        thead {
            tr {
                background-color: #4B2763;

                th {
                    color: #FFF;
                    font-family: 'Lexend';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-left: 42px;
                }
            }
        }

        tbody {
            tr:nth-child(even) {
                background-color: #ffffff;
                border: 1px solid rgba(221, 221, 221, 0.60);
            }

            tr {
                background-color: transparent;
                margin-top: 10px;
                margin-bottom: 10px;

                td {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-left: 42px;
                    color: #242934;
                    font-family: 'Lexend';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    border-bottom: none;

                    .accepted_inner {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        background: rgba(234, 158, 88, 0.10);
                        border: none;
                        outline: none;
                        box-shadow: none;
                        width: 101.41px;
                        height: 33px;
                        color: #D68C49;
                        font-family: 'Lexend';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        p {
                            margin-left: 8px;
                        }
                    }

                    .buttonright {
                        border-radius: 5px;
                        background: #37A000;
                        width: 33px;
                        height: 33px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: none;
                    }
                }
            }
        }
    }
}

.property-panel-content .e-checkbox-wrapper.margaret .e-frame {
    background-color: #3EA7A2;
    border-color: transparent;
}

.property-panel-content .e-checkbox-wrapper.robert .e-frame {
    background-color: #DB884D;
    border-color: transparent;
}

.property-panel-content .e-checkbox-wrapper.laura .e-frame {
    background-color: #3D64FD;
    border-color: transparent;
}

.ppppppp {
    padding-left: 0px;
}

.detailmodal.detailbooking .arrowimg button {
    background-color: transparent;
    padding: 0px;
    color: #000;
    width: unset;
}

.detailbooking {
    .downallinputs {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .arrowimg {
        p {
            color: #000;
            text-align: center;
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: 25px;
        }
    }

    .dropdown-toggle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        background: #FFF;
        padding-right: 30px;
        color: #000;
        font-family: 'Poppins';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: 0.16px;
        outline: none;
        box-shadow: none;
        border-radius: 8px;
    }

    .oooookkkkk {
        border-radius: 8px !important;
        background: #4B2763 !important;
        height: 55px !important;
        color: #FFF !important;
        text-align: center !important;
        font-family: 'Poppins' !important;
        font-size: 15px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 33px !important;
        letter-spacing: 0.15px !important;
        padding-left: 68px !important;
        padding-right: 68px !important;
        margin-top: 28px !important;
        outline: none !important;
        box-shadow: none !important;
        width: 289px !important;
        margin: 0 auto !important;
        margin-top: 28px !important;
    }

    .dropdown-menu.show {
        width: 100%;
    }

    .modal-dialog {
        max-width: 558px;
    }

    .modal-content {
        border-radius: 32px;
        background: #FFF;
        padding: 30px 30px;
    }

    .timepicker {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        background: #FFF;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
    }

    .css-1rv8zyt-MuiStack-root>:not(style):not(style) {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        background: #FFF;
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        margin-right: 2px;
    }
}

.bookingdetailcancelbutton.jhhbhjghjghjghgjg .modal-dialog {
    max-width: 600px !important;
}

.bookingdetailcancelbutton.jhhbhjghjghjghgjg {

    .asdasdasdasdasdcc {
        margin-bottom: 27px;
    }

    .outerinnerfeild {
        margin-bottom: 22px;
    }

    .asjbhashjbascjbskjacb {
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.40);
        background: #FFF;
        padding-left: 14px;
        padding-top: 22px;
        padding-bottom: 22px;
        text-decoration: none;
    }
}


.outerinnerfeild {}

.innnerrrr {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 22px;

    .leftsidere {
        width: 101px;

        h3 {
            color: rgba(0, 0, 0, 0.60);
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
    }

    .rightsideer {
        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: -6px;
        }
    }

    .leftsidere.oopp {
        width: 200px;
    }
}

.ooooacascas {
    border: none;
    outline: none;
    box-shadow: none;
}

// table { 
//     border-collapse: separate; 
//     border-spacing: 0 10px; 
//     margin-top: -10px; /* correct offset on first border spacing if desired */
// }
// td {
//     border: solid 1px #000;
//     border-style: solid none;
//     padding: 10px;
//     background-color: cyan;
// }
// td:first-child {
//     border-left-style: solid;
//     border-top-left-radius: 10px; 
//     border-bottom-left-radius: 10px;
// }
// td:last-child {
//     border-right-style: solid;
//     border-bottom-right-radius: 10px; 
//     border-top-right-radius: 10px; 
// }
.bookingcheckbox {
    .form-check-input:focus {
        box-shadow: none !important;
    }

    .form-check-input[type=checkbox] {
        border: none !important;
        border-radius: 4px;
        background-color: #D9D9D9;
        width: 22.438px;
        height: 22.438px;
    }

    .form-check-input:checked {
        background-color: #4B2763;
    }
}

.bookingdetailcancelbutton {
    .modal-dialog {
        max-width: 746px !important;
    }

    .arrowimg {
        h4 {
            color: #2E2B2B;
            text-align: center;
            font-family: 'Lexend';
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        h5 {
            color: rgba(0, 0, 0, 0.60);
            text-align: center;
            font-family: 'Lexend';
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-top: 15px;
            margin-bottom: 46px;
        }

        .buttoncancel {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            border-radius: 8px !important;
            background: #E5343D !important;
            color: #FFF !important;
            text-align: center !important;
            font-family: 'Lexend' !important;
            font-size: 15px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 33px !important;
            letter-spacing: 0.15px !important;
            width: 225px !important;

            &.outlined{
                border: 1px solid rgba(0, 0, 0, 0.40)!important;
                background: transparent !important;
                color: #2E2B2B !important;
            }
        }
    }
}

.d-n {
    display: none;
}


.pagination {
    justify-content: flex-end;
    margin-top: 50px;
}

.page-link {
    background: #4B2763 !important;
    color: #fff;
    border: 1px solid #333333;
}

.page-item.active .page-link {
    background-color: #f7f7f7 !important;
    color: #000 !important;
    border-color: #dddddd !important;
}

.page-link:focus {
    color: transparent !important;
    box-shadow: none !important;
}

.page-link:hover {
    color: #fff !important;
}

.sp-image {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 11px;
    border-radius: 50px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.m-l-15 {
    margin-left: 15px;
}

.service-cont {
    max-height: 520px;
    overflow: scroll;
}

.asasasasasas {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.bookingdetailcancelbutton .arrowimg .buttoncancel.acceptbutton {
    background-color: #4B2763 !important;
}

.action-btns {
    position: absolute;
    right: 0px;
    top: -80px;
}

.p-r {
    position: relative;
}

.disable-btn {
    pointer-events: none;
    opacity: 0.5;
}

.asasasasasas {
    .rigiside {
        h6 {
            padding-top: 9px !important;
            color: #000 !important;
            font-family: 'Poppins' !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }
    }
}

.m-l-170 {
    margin-left: 172px
}