.storemain {
    margin-bottom: 100px;
    .texthere {
        margin-top: 58px;
        margin-bottom: 58px;

        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .outer_main {
        border-radius: 8px;
        background: #FFF;
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;
        .leftsideee {
            border-radius: 10px;
            width: 85px;
            height: 85px;

            img {
                border-radius: 10px;
                width: 85px;
                height: 85px;
            }
        }

        .rightsideeee {
            margin-left: 27px;
            width: 100%;
            h3{
                color: #000;
                font-family: 'Poppins';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                padding-top: 16px;
                padding-bottom: 10px;
            }
            p{
                color: rgba(0, 0, 0, 0.60);
                font-family: 'Poppins';
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 17px;
            }
            .outermain {
                .rightside {
                    .outermainright {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .beatury-outer {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            h4 {
                                margin-right: 6.34px;
                                color: #7738A2;
                                text-align: center;
                                font-family: 'Lexend';
                                font-size: 9px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                border-radius: 2px;
                                border: 0.5px solid rgba(0, 0, 0, 0.10);
                                background: #EDF0FF;
                                padding: 4px 10px;
                            }

                            h5 {
                                color: #236512;
                                text-align: center;
                                font-family: 'Lexend';
                                font-size: 9px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                border-radius: 2px;
                                border: 0.5px solid rgba(0, 0, 0, 0.10);
                                background: #EDFFF0;
                                padding: 4px 10px;
                            }
                        }

                        .upperright{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }

                        .text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            h4 {
                                color: rgba(0, 0, 0, 0.60) !important;
                                font-family: 'Poppins' !important;
                                font-size: 12px !important;
                                font-style: normal !important;
                                font-weight: 400 !important;
                                line-height: 17px !important;
                                padding-bottom: 0px !important;
                            }

                            h5 {
                                color: rgba(0, 0, 0, 0.60);
                                font-family: 'Poppins';
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 17px;
                            }
                        }

                        .ratings {
                            margin-right: 10px;
                        }

                        .ratings i {

                            color: #cecece;
                            font-size: 32px;
                        }

                        .rating-color {
                            color: #fbc634 !important;
                        }

                        .review-count {
                            font-weight: 400;
                            margin-bottom: 2px;
                            font-size: 24px !important;
                        }

                        .small-ratings i {
                            color: #cecece;
                        }

                        .review-stat {
                            font-weight: 300;
                            font-size: 18px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
        }
    }
}

.storemain .outer_main .rightsideeee .outermain .rightside .outermainright .ratings i {
    margin-right: 2px;
    font-size: 15px !important;
}

.storemain .outer_main .rightsideeee .outermain .rightside .outermainright .rating-color {
    color: #ffa417 !important;
}

.storemain .outer_main .rightsideeee .outermain .rightside .outermainright .ratings {
    margin-right: 0px;
}

.kkkkkkkkk{
    text-decoration: none!important;
}