.basket {
    margin-bottom: 100px;

    .texthere {
        margin-top: 58px;
        margin-bottom: 58px;

        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .outer_main {
        h1 {
            color: #4B2763;
            font-family: 'Lexend';
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .downrows {
            margin-top: 68px;
            margin-bottom: 44px;
            padding-bottom: 12px;
            border-bottom: 2px solid #dfdede;

            .booking {



                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .location {
                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .subtotal {
                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .cartrow {
            padding-bottom: 22px;
            margin-bottom: 28px;
            border-bottom: 2px dotted #dfdede;

            .downtipadd {
                .mm{
                    display: block;
                }
                .nn{
                    display: none;
                }
                button {
                    width: 131.635px;
                    height: 50px;
                    border: none;
                    outline: none;
                    border: none;
                    color: #FFF;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    border-radius: 10px;
                    background: #4B2763;
                    margin-top: 20px;
                }

                input {
                    margin-top: 20px;
                    width: 274.336px;
                    height: 60px;
                    border-radius: 10px;
                    border: 1px solid #E4E4E4;
                    background: #FFF;
                    outline: none;
                    box-shadow: none;
                    color: rgba(0, 0, 0, 0.80);
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    padding-left: 28px;
                }
            }

            .mapsection {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 5px;

                img {
                    margin-right: 12px;
                }

                p {
                    color: #707070;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration-line: underline;
                }
            }

            .booking {


                h2 {
                    color: #2E2B2B;
                    font-family: 'Lexend';
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.25px;
                    padding-bottom: 8px;
                }

                h4 {
                    color: #707070;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 4px;
                }

                h5 {
                    color: #707070;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding-bottom: 8px;
                }

                h6 {
                    color: #707070;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    span {
                        color: #707070;
                        font-family: 'Poppins';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .location {
                h4 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .subtotal {
                h4 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .cross {
                background-color: #FB5402;
                width: 27px;
                height: 27px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .down_outer_main {
        .buttondivhere {
            width: 100%;
            display: flex;
            justify-content: center;

            .paymentproceed {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #4B2763;
                height: 55px;
                color: #FFF;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                outline: none;
                // padding-left: 170px;
                margin-top: 55px;
                // padding-right: 170px;
                width: 100%;
            }
        }

        .emailname {
            margin-top: 34px;
            margin-bottom: 40px;

            .error_message {
                color: #C82A2F;
                font-family: 'Poppins';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-left: 5px;
            }

            .namefeild {
                margin-top: 15px;
            }

            .outerinnerfeild {
                input {
                    border-radius: 10px;
                    background: #FFF;
                    padding: 22px 28px;
                    color: rgba(0, 0, 0, 0.80);
                    font-family: 'Poppins';
                    font-size: 16px;
                    width: 100%;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    outline: none;
                    box-shadow: none;
                    border: none;
                }
            }
        }

        .upperbarhere {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background: #FFF;
            padding: 20px 18px;
            margin-bottom: 33px;

            h5 {
                padding-left: 10px;
                color: #4B2763;
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 35px;
                letter-spacing: 0.16px;

                a {
                    color: #4B2763;
                }
            }
        }

        .downcard {
            label {
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.2px;
            }

            textarea {
                border-radius: 10px;
                background: #F4F4F4;
                height: 136px;
                color: #000;
                font-family: 'Poppins';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.16px;
                padding: 22px 26px;
                margin-top: 13px;
                margin-bottom: 25px;
                width: 100%;
                border: none;
                outline: none;

                ::placeholder {
                    color: rgba(0, 0, 0, 0.40);
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.16px;
                }
            }
        }

        .termscondition {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 25px;

            .form-check-input:checked {
                background-color: #4B2763;
                border-color: #4B2763;
            }

            h6 {
                color: rgba(0, 0, 0, 0.60);
                margin-left: 10px;
                font-family: 'Poppins';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                a {
                    color: #4B2763;
                }
            }
        }

        .billarea {
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.20);
            background: #FFF;
            padding-top: 18px;
            padding-left: 32px;
            padding-right: 32px;
            padding-bottom: 54px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-top: -78px;

            .paymentproceed {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #4B2763;
                height: 55px;
                color: #FFF;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                outline: none;
                padding-left: 117px;
                margin-top: 55px;
                padding-right: 117px;
            }

            h4 {
                color: #4B2763;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-bottom: 40px;
            }

            .totalbill {
                width: 100%;

                .ssss {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 22px;

                    h5 {
                        color: #787878;
                        font-family: 'Poppins';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    h6 {
                        color: #000;
                        font-family: 'Poppins';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .sssstotal {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    h5 {
                        color: #4B2763;
                        font-family: 'Poppins';
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    h6 {
                        color: #4B2763;
                        font-family: 'Poppins';
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

.jkjkjkjkj {
    padding-left: 0px;
    padding-right: 60px;
}

.skjdcbsjbvdshbvvds {
    padding-right: 0px;
}

.creditcard_off {
    width: 630px !important;
    border: 1px solid rgba(26, 25, 25, 0.10);
    background: #FAFAFA;
    box-shadow: -20px 4px 200px 0px rgba(0, 0, 0, 0.15);

    .creditcardbuton {
        border-radius: 8px;
        background: #4B2763;
        width: 289px;
        height: 55px;
        color: #FFF;
        text-align: center;
        font-family: 'Lexend';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0.15px;
        outline: none;
        box-shadow: none;
        border: none;
        margin: 0 auto;
        margin-top: 0px;
        padding: 10px;
    }

    .offcanvas-header {
        padding-bottom: 14px;
        margin-bottom: 8px;
        justify-content: center !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.08);
        padding-top: 40px;

        h5 {
            color: rgba(0, 0, 0, 0.60);
            text-align: center;
            font-family: 'Poppins';
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.22px;
        }
    }

    .offcanvas-body {
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: column;

        .mainoutersidecredit {
            padding-left: 10px;
            padding-right: 10px;
        }

        .dropdown-toggle {
            white-space: nowrap;
            border-radius: 10px;
            background: #FFF;
            width: 100%;
            padding: 22px 28px;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(0, 0, 0, 0.80);
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            box-shadow: none;
        }

        .dropdown-menu.show {
            width: 100%;
        }

        .outerinnerfeild {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 22px;

            label {
                color: rgba(0, 0, 0, 0.8);
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.18px;
                padding-bottom: 10px;
            }

            input {
                border-radius: 10px;
                background: #FFF;
                color: rgba(0, 0, 0, 0.80);
                font-family: 'Poppins';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                padding: 22px 28px;
                border: none;
                outline: none;
                box-shadow: none;

                ::placeholder {
                    color: rgba(0, 0, 0, 0.80);
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }
        }
    }

    .servicedetailhere {
        color: #4B2763;
        font-family: 'Poppins';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .billarea {
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.20);
        background: #FFF;
        padding-top: 16px;
        padding-left: 23px;
        padding-right: 32px;
        padding-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        h4 {
            color: #4B2763;
            font-family: 'Poppins';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-bottom: 10px;
        }

        .totalbill {
            width: 100%;

            .ssss {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;

                h5 {
                    color: #787878;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .sssstotal {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                    color: #4B2763;
                    font-family: 'Poppins';
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                h6 {
                    color: #4B2763;
                    font-family: 'Poppins';
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}

.detailmodal {
    text-align: center;

    .modal-dialog {
        max-width: 700px;
    }

    .modal-content {
        text-align: center;
        border-radius: 32px;
        background: #FFF;
        padding: 30px 30px;
    }

    .arrowimg {
        p {
            color: #2E2B2B;
            font-family: 'Lexend';
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            margin-top: 50px;
        }

        h5 {
            color: rgba(0, 0, 0, 0.60);
            text-align: center;
            font-family: 'Lexend';
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-top: 33px;
            margin-bottom: 73px;
        }

        button {
            border-radius: 8px;
            background: #4B2763;
            outline: none;
            box-shadow: none;
            width: 289px;
            height: 55px;
            color: #FFF;
            text-align: center;
            font-family: 'Lexend';
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px;
            letter-spacing: 0.15px;
        }
    }
}

.Login.samelogin {
    .centerdiv_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        border-right: 3px solid #EBEBEB;
        position: sticky;
        top: 0;
    }

    .centerdiv_right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        min-height: 100vh;

        .outermain_form {
            width: 566px;

            .cnctwltimg {
                margin-bottom: 44.4px;
            }

            h2 {
                color: #151515;
                font-family: 'Poppins';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 43px;
                letter-spacing: -0.8px;
                padding-bottom: 8.81px;
            }

            h4 {
                color: #807E7E;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
                padding-bottom: 66.35px;
            }

            .outerinnerfeild {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 24.83px;

                label {
                    color: rgba(0, 0, 0, 0.80);
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.18px;
                    padding-bottom: 13px;
                }

                input {
                    border-radius: 10px;
                    background: #F6F6F6;
                    padding: 22px 28px;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    ::placeholder {
                        color: rgba(0, 0, 0, 0.80);
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }
            }

            .forgetpasswordfeild {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 60.26px;

                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }
            }

            .button-s {
                border-radius: 8px;
                background: #4B2763;
                width: 100%;
                height: 55px;
                color: #FFF;
                text-align: center;
                font-family: 'Lexend';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0.25px;
                border: none;
                outline: none;
                box-shadow: none;
                margin-bottom: 120px;
                cursor: pointer;
            }

            h3 {
                text-align: center;
                color: #666363;
                font-family: 'Poppins';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 43px;
                letter-spacing: -0.5px;

                span {
                    cursor: pointer;
                    font-weight: 700;
                    color: #4B2763;
                }
            }
        }
    }
}


.basketpagecss{
    .Navbar-new-filter{
        .buttonforbasket{
            display: block!important;
            height: 50px;
            border: none;
            outline: none;
            border: none;
            color: #FFF;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            border-radius: 10px;
            background: #4B2763;
            width: 100px;
            background-color: transparent;
            border: 1px solid #4B2763;
            color: #4B2763;
            &:hover{
                background: #4B2763; 
                color: #fff;
            }
        }
        .outermain-filter{
            display: none;
        }
    }
}
.disabled-btn{
    background: silver!important;
    pointer-events: none;
}


.form-select{
    border-radius: 10px;
    background: #FFF;
    color: rgba(0, 0, 0, 0.8);
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 22px 28px;
    border: none;
    outline: none;
    box-shadow: none;
}

.form-select:focus{
    border: none;
    box-shadow: none;
}

.dropdownslect{
    position: relative;
    .cnctwltimsdsdsddsg{
        position: absolute;
        right: 28px;
        top: 50px;
    }
}

.hide{
    display: none;
}

.nothide{
    display: block;
}
.disabled-link{
    pointer-events: none;
}
.c-p{
    cursor: pointer;
}

// stripe form styles
.payment-form form {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .payment-form form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .payment-form form button {
    background-color: #4B2763;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  .stripe-btn{
    background: #4B2763;
    margin-top: 20px;
  }
  .card-stripe{
    padding: 20px 0px;
  }
  .f-width{
    width: 100%!important;
  }

  .hsdbhsdbhsdbsh{
    position: relative;
}

.eyeimageshere{
    position: absolute;
    right: 21px;
    top: 44px;
    width: 40px;
    z-index: 99999999999999;
    pointer-events: unset!important;
    cursor: pointer;
}
.d-n{
    display: none;
}
.c-red{
    color: red;
}
.c-w{
    color: white;
    padding: 5px;
    border-radius: 6px;
}
.bg-red{
    background-color: red!important;
    border-color: red;
}
