.FrontMain_Stores.jsdsjhfsjdfhdsf {
  background: #ebebeb;
  padding-top: 50px;
  // padding-bottom: 18px;
}

.bwkbfewkjbfewijfkwjd {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;

  .rightsidemaindummy {
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
  }

  .leftsidemaindummy {
    .ssscvsv {
      margin-top: 30px;
      margin-bottom: 20px;

      .asdasdasdasdasd {
        padding: 0px !important;
      }
    }

    margin-top: 128px;

    h3 {
      color: #000;
      font-family: "Poppins";
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h4 {
      margin-top: 43px;
      color: #000;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
    }

    .ssscvsv {
      gap: 20px;

      .ffffffsdfdsfdsffcfc {
        // width: 180px;
        height: 52px;
        // margin-right: 16px;
      }
      img {
        width: 180px;
        height: 52px;
        // margin-right: 16px;
      }
    }
  }
}

.asdasdasdasdasd {
  //   width: 180px;
  height: 52px;
  //   margin-right: 16px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.container-main-selfss {
  width: 1500px;
  margin: 0 auto;
}

@media (max-width: 1680px) {
  .container-main-selfss {
    width: 1380px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    margin-top: 70px;
  }
}

.mainouterhidden {
  position: relative !important;
}

@media (max-width: 1500px) {
  .container-main-selfss {
    width: 1260px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    margin-top: 36px;
  }
}

@media (max-width: 1400px) {
  .container-main-selfss {
    width: 1220px;
    margin: 0 auto;
  }
}

@media (max-width: 1300px) {
  .container-main-selfss {
    width: 1170px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h4 {
    margin-top: 25px;
    color: #000;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
  }
}

@media (max-width: 1050px) {
  .container-main-selfss {
    width: 940px;
    margin: 0 auto;
  }

  .mmmmkkmm {
    display: none;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    margin-top: 43px;
  }
  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 30px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h4 {
    margin-top: 25px;
    color: #000;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
  }
}

@media (max-width: 900px) {
  .container-main-selfss {
    width: 800px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 30px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    margin-top: 20px;
  }
}

@media (max-width: 800px) {
  .bwkbfewkjbfewijfkwjd .leftsidemaindummy .ssscvsv {
    display: flex;
  }
  .container-main-selfss {
    width: 700px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy .ssscvsv img {
    width: 165px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    margin-top: 14px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .mobiledesign_here_we_have {
    .leftsidemaindummy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .rightsidemaindummy {
      display: flex;
      justify-content: center;
    }
  }
  .container-main-selfss {
    width: 720px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy .ssscvsv img {
    width: 165px;
  }

  //   .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
  //     margin-top: -20px;
  //   }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .mobiledesign_here_we_have {
    // position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 11111111;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }
  .bwkbfewkjbfewijfkwjd .rightsidemaindummy .desktop {
    display: none;
  }

  .bwkbfewkjbfewijfkwjd .rightsidemaindummy .mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  .bwkbfewkjbfewijfkwjd .leftsidemaindummy .ssscvsv {
    flex: unset !important;
  }
  .FrontMain_Stores.jsdsjhfsjdfhdsf {
    padding-top: 90px;
    .rightsidemaindummy {
      display: flex;
      justify-content: center;
    }
  }
  .sdhsdsdsdfdsf {
    margin-right: 0px !important;
  }

  .container-main-selfss {
    width: 500px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .container-main-selfss {
    width: 400px;
    margin: 0 auto;
  }

  .mmmmkkmm {
    display: unset;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    text-align: center;
    margin-bottom: 30px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 22px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h4 {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .jkjbjbhjbh {
    margin-right: 0px !important;
  }

  .container-main-selfss {
    width: 340px;
    margin: 0 auto;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy {
    text-align: center;
    margin-bottom: 30px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy .ssscvsv img {
    width: 135px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h3 {
    font-size: 22px;
  }

  .bwkbfewkjbfewijfkwjd .leftsidemaindummy h4 {
    font-size: 12px;
  }
}

.FrontMain_Stores.jsdsjhfsjdfhdsf {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.kasjdasjdasdasd {
  color: #4b2763;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;

  a {
    color: #4b2763;
  }
}
