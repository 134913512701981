@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Quantico:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Quantico:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Quantico:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

div#root {
  height: 100vh;
}

body {
  background: #fafafa;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.row {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

.container-main-self {
  width: 1500px;
  margin: 0 auto;
}

@media (max-width: 1680px) {
  .container-main-self {
    width: 1380px;
    margin: 0 auto;
  }
}

.mainouterhidden {
  position: relative !important;
}

@media (max-width: 1500px) {
  .container-main-self {
    width: 1260px;
    margin: 0 auto;
  }
}

@media (max-width: 1400px) {
  .container-main-self {
    width: 1220px;
    margin: 0 auto;
  }
}

@media (max-width: 1300px) {
  .container-main-self {
    width: 1170px;
    margin: 0 auto;
  }
}
@media (max-width: 1200px) {
  .container-main-self {
    width: 1110px;
    margin: 0 auto;
  }
}
@media (max-width: 1100px) {
  .container-main-self {
    width: 1030px;
    margin: 0 auto;
  }
}

@media (max-width: 1050px) {
  .container-main-self {
    width: 950px;
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .container-main-self {
    width: 820px;
    margin: 0 auto;
  }
}

@media (max-width: 800px) {
  .container-main-self {
    width: 700px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .container-main-self {
    width: 300px;
    margin: 0 auto;
  }

  .Navbar-new-filter .outermain-filter {
    overflow: hidden !important;
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loaderStart {
  background-color: #00000059;
}

.react-datepicker-popper {
  z-index: 1000;
}
