.Specialdetail {
    .sdasasdasddasd {

        // justify-content: ;
        .leftsidefirst {
            width: 100%;

            .feerffer {
                color: #000;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 10px;
            }
            .cvbcvbvcvcvb{
                color: #3EA7A2!important;
            }
        }
    }

    .dsfdwfdsfsdfsdf {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .rrttrrtt {
            width: 100%;

            .innnerrrr {
                margin-bottom: 10px!important;
                h3 {
                    white-space: nowrap;
                }
                &.reschedule{
                    h3 {
                            white-space: unset;
                        }
                }
            }
        }
    }

    .leftsidere {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    margin-bottom: 100px;

    .texthere {
        margin-top: 58px;
        margin-bottom: 58px;

        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 35px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .outermain {
        .uppermainfirst {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 39px;
            margin-bottom: 30px;
            border-bottom: 3px dotted rgba(182, 182, 182, 0.4588235294);
            ;

            .leftsidefirst {
                .outmain-main {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .leftsideinner {
                        width: 133px;
                        height: 133px;
                        object-fit: cover;
                        border-radius: 50%;
                        margin-right: 40px;

                        img {
                            width: 133px;
                            height: 133px;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .rightsideinner {

                        h2 {
                            color: #000;
                            font-family: 'Poppins';
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px;
                        }

                        h3 {
                            color: rgba(3, 3, 3, 0.70);
                            font-family: 'Poppins';
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding-top: 14px;
                            padding-bottom: 6px;
                        }

                        .upperright {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }

                        .text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            h4 {
                                color: rgba(0, 0, 0, 0.60) !important;
                                font-family: 'Poppins' !important;
                                font-size: 12px !important;
                                font-style: normal !important;
                                font-weight: 400 !important;
                                line-height: 17px !important;
                                padding-bottom: 0px !important;
                            }

                            h5 {
                                color: rgba(0, 0, 0, 0.60);
                                font-family: 'Poppins';
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 17px;
                            }
                        }

                        .ratings {
                            margin-right: 10px;
                        }

                        .ratings i {

                            color: #cecece;
                            font-size: 32px;
                        }

                        .rating-color {
                            color: #fbc634 !important;
                        }

                        .review-count {
                            font-weight: 400;
                            margin-bottom: 2px;
                            font-size: 24px !important;
                        }

                        .small-ratings i {
                            color: #cecece;
                        }

                        .review-stat {
                            font-weight: 300;
                            font-size: 18px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }

            .rightsidefirst {
                margin-right: 200px;

                .bookingdetailcpecilist {
                    color: #FFF;
                    text-align: center;
                    font-family: 'Poppins';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 33px;
                    letter-spacing: 0.15px;
                    width: 289px;
                    height: 55px;
                    border-radius: 8px;
                    background: #4B2763;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    margin-top: 27px;
                }

                .maplocations {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    h5 {
                        color: rgba(3, 3, 3, 0.70);
                        font-family: 'Poppins';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;
                        margin-right: 60px;
                    }
                }

                .nav-pills .nav-link {
                    border-radius: 8px;
                    background: #DADADA;
                    color: #707070;
                    text-align: center;
                    font-family: 'Poppins';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 33px;
                    letter-spacing: 0.15px;
                    height: 55px;
                    width: 289px;
                }

                .nav-pills .nav-link.active,
                .nav-pills .show>.nav-link {
                    border-radius: 8px;
                    background: #4B2763;
                    color: #FFF;
                    text-align: center;
                    font-family: 'Poppins';
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 33px;
                    letter-spacing: 0.15px;
                    height: 55px;
                    width: 289px;
                }
            }
        }

        .textjust {
            margin-bottom: 35px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .haiers {
                background-color: #2E2B2B;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 9px;
            }

            h4 {
                color: #000;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .bookingdate {
            label {
                color: #000;
                font-family: 'Poppins';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 5px;
            }

            .inner-11 {
                position: relative;
                width: 470px;

                input {
                    border-radius: 5px;
                    border: 1px solid #4B2763;
                    background: #EDF0FF;
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 470px;
                    height: 55px;
                    padding-left: 12px;
                    outline: none;
                }

                .cnctwltimsdsdsddsg {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    pointer-events: none;
                    cursor: pointer;
                }
            }
        }

        .timeavaible {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 35px;
            margin-bottom: 27px;
            padding-bottom: 27px;
            border-bottom: 3px dotted rgba(182, 182, 182, 0.4588235294);

            .sadasasdasda {
                padding-top: 3px;
            }

            .inneravable {
                margin-left: 18px;

                h5 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                h6 {
                    color: #4B2763;
                    font-family: 'Poppins';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .allslots {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: 76%;

            .slotdesignsimple {
                margin-right: 20px;
                margin-bottom: 12px;
                border-radius: 4px;
                background: #EDF0FF;
                color: #4B2763;
                text-align: center;
                width: 96px;
                height: 36px;
                cursor: pointer;

                h2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    height: 100%;

                }

                &:hover {
                    background-color: #4B2763;

                    h2 {
                        color: #fff;
                    }
                }
            }

            .slotdesigndisabled {
                border-radius: 4px;
                background: #DADADA;
                color: #707070;
                text-align: center;
                width: 96px;
                height: 36px;
                margin-right: 20px;
                margin-bottom: 12px;
                pointer-events: none;

                h2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    height: 100%;

                }
            }
        }

        .buttonaddtobacket {
            margin-top: 100px;
            border-radius: 10px;
            background: #4B2763;
            width: 350px;
            height: 55px;
            color: #FFF;
            text-align: center;
            font-family: 'Poppins';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 33px;
            letter-spacing: 0.16px;
        }
    }

}


.Specialdetail .outermain .uppermainfirst .leftsidefirst .outmain-main .rightsideinner .ratings i {
    margin-right: 2px;
    font-size: 15px !important;
}

.Specialdetail .outermain .uppermainfirst .leftsidefirst .outmain-main .rightsideinner .rating-color {
    color: #ffa417 !important;
}

.Specialdetail .outermain .uppermainfirst .leftsidefirst .outmain-main .rightsideinner .ratings {
    margin-right: 0px;
}

.slot-selected {
    background-color: #4B2763 !Important;
    color: white !important;
    border-radius: 4px;
}

.spciallistdetailpagecss {
    .Navbar-new-filter {
        .outermain-filter {
            display: none;
        }
    }
}

.disabled-btn {
    background: silver !important;
    pointer-events: none;
}