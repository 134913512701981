.Footermain {
    background: #EBEBEB;
    padding-top: 50px;
    padding-bottom: 18px;

    .container-main-self {
        border-bottom: 1px solid #4B2763;
        .logossss {
            h2 {
                color: #4B2763;
                font-family: 'Inter';
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-bottom: 20px;
            }

            h6 {
                color: #4B2763;
                font-family: 'Inter';
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-bottom: 38px;
            }

            .logoss-here {
                .llpllp {
                    padding-right: 40px;
                }
            }
        }

        .mainheadingabout {
            h3 {
                color: #4B2763;
                font-family: 'Inter';
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding-bottom: 30px;
            }
            a{
                text-decoration: none;
            }
            .inner-link {
                margin-bottom: 42px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                h3 {
                    padding-left: 14px!important;
                    color: #4B2763!important;
                    font-family: 'Inter'!important;
                    font-size: 16px!important;
                    font-style: normal!important;
                    font-weight: 700!important;
                    line-height: normal!important;
                    padding-bottom: 0px!important;
                }
            }
        }
    }
    .container-main-self.downgrade{
        img{
            padding-top: 20px;
            padding-bottom: 10px;
        }
        border: none;
    }
}

.categories{
background: #f3f3f3;
padding: 20px 0px;
.innercateriesrow{
    display: flex;
    overflow-x: scroll;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.innercateriesrow::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .innercateriesrow {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
button{
    margin-right: 6.34px;
    margin-left: 13px;
    color: #7738A2;
    text-align: center;
    font-family: "Lexend";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    background: #EDF0FF;
    padding: 8px 16px;
    &:hover{
        background-color: #4B2763;
        color: #fff;
    }
}

.primaryChip {
    background-color: #eeeeee;
    color: black;
    white-space: nowrap;
}
}


@media (max-width: 1500px) {
    .Footermain .container-main-self .logossss h2{
        font-size: 20px!important;
        padding-bottom: 16px!important;
    }
    .Footermain .container-main-self .logossss h6{
        font-size: 16px!important;
        padding-bottom: 28px!important;
    }
    .Footermain .container-main-self .mainheadingabout h3{
        font-size: 20px!important;
        padding-bottom: 24px!important;
    }
    .Footermain .container-main-self .mainheadingabout .inner-link h3{
        font-size: 15px!important;
        font-weight: 600!important;
    }
    .Footermain .container-main-self .mainheadingabout .inner-link{
        margin-bottom: 36px!important;
    }
}