.Signup.samelogin {
    .centerdiv_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        border-right: 3px solid #EBEBEB;
        position: sticky;
        top: 0;
    }

    .centerdiv_right {
        display: flex;
        justify-content: center;
        align-items: center;

        .outermain_form {
            width: 566px;

            .cnctwltimg {
                margin-top: 40px;
                margin-bottom: 37.4px;
            }

            h2 {
                color: #151515;
                font-family: 'Poppins';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 43px;
                letter-spacing: -0.8px;
                padding-bottom: 8.81px;
            }

            h4 {
                color: #807E7E;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
                padding-bottom: 42.35px;
            }

            .outerinnerfeild {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 24.83px;
                label {
                    color: rgba(0, 0, 0, 0.80);
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.18px;
                    padding-bottom: 13px;
                }

                input {
                    border-radius: 10px;
                    background: #F6F6F6;
                    padding: 22px 28px;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    ::placeholder {
                        color: rgba(0, 0, 0, 0.80);
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }
            }

            .forgetpasswordfeild {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 60.26px;

                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }
            }

            .button-s {
                border-radius: 8px;
                background: #4B2763;
                width: 100%;
                height: 55px;
                color: #FFF;
                text-align: center;
                font-family: 'Lexend';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0.25px;
                border: none;
                outline: none;
                box-shadow: none;
                margin-bottom: 35px;
                cursor: pointer;
            }

            h3 {
                text-align: center;
                color: #666363;
                font-family: 'Poppins';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 43px;
                letter-spacing: -0.5px;

                span {
                    cursor: pointer;
                    font-weight: 700;
                    color: #4B2763;
                }
            }
        }
    }
}

.Login.samelogin {
    .centerdiv_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        border-right: 3px solid #EBEBEB;
        position: sticky;
        top: 0;
    }

    .centerdiv_right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        min-height: 100vh;

        .outermain_form {
            width: 566px;

            .cnctwltimg {
                margin-bottom: 44.4px;
            }

            h2 {
                color: #151515;
                font-family: 'Poppins';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 43px;
                letter-spacing: -0.8px;
                padding-bottom: 8.81px;
            }

            h4 {
                color: #807E7E;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
                padding-bottom: 66.35px;
            }

            .outerinnerfeild {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 24.83px;

                label {
                    color: rgba(0, 0, 0, 0.80);
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.18px;
                    padding-bottom: 13px;
                }

                input {
                    border-radius: 10px;
                    background: #F6F6F6;
                    padding: 22px 28px;
                    border: none;
                    outline: none;
                    box-shadow: none;

                    ::placeholder {
                        color: rgba(0, 0, 0, 0.80);
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }
            }

            .forgetpasswordfeild {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 60.26px;

                h6 {
                    color: #000;
                    font-family: 'Poppins';
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    cursor: pointer;
                }
            }

            .button-s {
                border-radius: 8px;
                background: #4B2763;
                width: 100%;
                height: 55px;
                color: #FFF;
                text-align: center;
                font-family: 'Lexend';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0.25px;
                border: none;
                outline: none;
                box-shadow: none;
                margin-bottom: 120px;
                cursor: pointer;
            }

            h3 {
                text-align: center;
                color: #666363;
                font-family: 'Poppins';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 43px;
                letter-spacing: -0.5px;

                span {
                    cursor: pointer;
                    font-weight: 700;
                    color: #4B2763;
                }
            }
        }
    }
}


.Login.veriifactionmain {
    .modal-content {
        padding: 0px !important;
    }

    .Verification.samelogin {
        .mainformhere {
            .logindetail {
                .outerinnerfeild {
                    margin-bottom: 0px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    margin-bottom: 13px;
                    margin-top: 42px;
                    align-items: flex-start;

                    label {
                        color: #060505;
                        font-family: 'Poppins';
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.18px;
                        margin-bottom: 0px;
                    }

                    input {
                        border-radius: 10px;
                        background: #F6F6F6;
                        color: #060505;
                        font-family: 'Poppins';
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        width: 100%;
                        line-height: 16px;
                        padding-left: 20px;
                        height: 60px;
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }
                }
            }

            // min-height: 100vh;
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: center;
            width: 557px;
            margin: 0 auto;

            .cnctwltimg {
                padding-bottom: 28px;
            }

            h2 {
                color: #151515;
                text-align: center;
                font-family: 'Poppins';
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 55px;
                padding-bottom: 12px;
            }

            h4 {
                color: #807E7E;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
                padding-bottom: 14px;
            }

            h5 {
                color: #4B2763;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px;
                padding-bottom: 60px;
            }

            .inputs_codes {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;

                input {
                    width: 65px;
                    margin-right: 13px;
                    height: 60px;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 10px;
                    background: #F6F6F6;
                    text-align: center;
                    font-size: 32px;
                }
            }

            h6 {
                color: #807E7E;
                font-family: 'Poppins';
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 29px;
            }

            button {
                margin-top: 30px;
                border-radius: 8px;
                background: #4B2763;
                width: 100%;
                height: 55px;
                color: #FFF;
                text-align: center;
                font-family: 'Lexend';
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0.25px;
                border: none;
                outline: none;
                box-shadow: none;
                margin-bottom: 20px;
                cursor: pointer;
            }

            h3 {
                color: #F00;
                font-family: 'Poppins';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 43px;
                letter-spacing: -0.4px;
                text-decoration-line: underline;

            }
        }
    }
}

.fklexvalue{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    .outerinnerfeild{
        width: 100%;
    }
}
.opopopopop {
    text-decoration: none;
    color: #4B2763;
}
.c-red{
    color: red;
}
.c-g{
    color: green;
}
.m-b-40{
    margin-bottom: 40px;
}
.p-18{
    padding: 18px;
}
.m-t-20{
    margin-top: 20px;
}
.disabled-btn{
    background: silver!important;
    pointer-events: none;
}
.disabled-link{
    pointer-events: none;
}
.c-p{
    cursor: pointer;
}
// .PhoneInputCountry{
//     display: none;
// }
.PhoneInput{
    width: 100%;
}
.d-n{
    display: none;
}

.hsdbhsdbhsdbsh{
    position: relative;
}

.eyeimageshere{
    position: absolute;
    right: 21px;
    top: 44px;
    width: 40px;
    z-index: 99999999999999;
    pointer-events: unset!important;
    cursor: pointer;
}