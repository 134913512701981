.Navbar-new {
  .container-fluid {
    padding: 0px;
  }

  .navbar {
    padding-top: 30px;
    background: #fafafa;
    padding-bottom: 36px;

    .profile {
      .knmkmkmkmk {
        background-color: transparent !important;
        border: none;
        outline: none;
        box-shadow: none;
      }

      .dropdown-menu.show {
        z-index: 11111111111;
        left: -157px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
        padding: 22px;
        width: 272px;

        .bookingheading {
          color: #808285;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 14px;
        }

        .uppermenubar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 9px;
          padding-bottom: 13px;
          border-bottom: 1px solid #f3f4f6;

          .leftsidelogin {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .profileimage {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              margin-right: 8px;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }
            }

            .textss {
              .loginnavbar {
                cursor: pointer;
                text-decoration: underline;
              }

              h4 {
                color: #000;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              h5 {
                color: #000;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                a {
                  color: #000;
                }
              }
            }
          }
        }

        .ppppllppp {
          position: relative;

          .rightsidearrownew {
            position: absolute;
            right: 4px;
            top: 4px;
            pointer-events: none;
          }
        }

        .midrange {
          // padding-bottom: 18px;
          // border-bottom: 1px solid rgb(233, 233, 233);
          padding-bottom: 14px;
          border-bottom: 1px solid rgb(233, 233, 233);
          margin-bottom: 13px;
        }

        .midrange.lllllllll {
          padding-bottom: 6px;
          border-bottom: none !important;
          margin-bottom: 0px !important;
        }

        .dropdown-item {
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          h6 {
            color: #000;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &:hover {
            background-color: transparent;
          }

          img {
            margin-left: 0px !important;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .navbar-light .navbar-nav .nav-link {
    color: #808285;
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-right: 59px;
    padding-top: 20px;
    padding-bottom: 0px;

    &:hover {
      color: #4b2763;
    }
  }

  .navbar-brand {
    margin-right: 100px;
  }

  .navbar-light .navbar-nav .nav-link.active {
    color: #4b2763;
  }

  .left-side-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h5 {
      color: #4b2763;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0px;
      padding-top: 19px;
      padding-left: 10px;
    }
  }

  .rightside {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cnctwltimgsfsdfdsfs {
      margin-left: 50px;
    }

    .cartadd {
      position: relative;

      .asdadasdas {
        position: absolute;
        top: -3px;
        background-color: #fff;
        border-radius: 50%;
        padding: 2px;
        right: -6px;
      }

      h2 {
        background-color: #46d07d;
        color: #fff;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1px 6px;
        border-radius: 50%;
        margin: 0px;
        padding-bottom: 0px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.maininderrightside {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;

  .buttonfind {
    margin-right: 0px;
  }
}

.Navbar-new-filter {
  background: #ebebeb;
  padding-top: 30px;
  padding-bottom: 33px;

  .outermain-filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left-side-main {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 6px 6px 6px 6px;
      background: #fff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

      .ooooo {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          color: #000;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        input {
          border: none;
          color: #000;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          box-shadow: none;
          outline: none;

          ::placeholder {
            color: rgba(0, 0, 0, 0.5);
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .inner-11 {
        border-right: 2px solid rgba(0, 0, 0, 0.1);

        .react-datepicker-ignore-onclickoutside {
          cursor: pointer;
        }

        cursor: pointer;
        position: relative;

        .cnctwltimg {
          position: absolute;
          right: 15px;
          pointer-events: none;
          cursor: pointer;
        }

        width: 200px;

        input {
          width: 164px;
        }

        padding: 13px 16px;
      }

      .inner-22 {
        border-right: none;
        position: relative;
        .cnctwltimsdchjudsbcg {
          position: absolute;
          right: 16px;
          cursor: pointer;
          pointer-events: none;
        }

        input {
          pointer-events: none;
        }

        //   input[type="text"] {
        //     pointer-events: initial;
        //   }
        input {
          width: 142px;
        }

        width: 200px;
        padding: 0px 16px;
        height: 50px;
      }

      .inner-33 {
        width: 313px;
        padding: 13px 16px;
      }
    }

    .mid-search {
      position: relative;

      input {
        border-radius: 6px 6px 6px 6px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
        height: 49.5px;
        color: #000;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
        border: none;
        width: 432.5px;
        padding-left: 59px;
      }

      img {
        position: absolute;
        top: 17px;
        left: 20px;
      }
    }

    .buttonfind {
      button {
        border-radius: 8px;
        background: #4b2763;
        width: 157px;
        height: 52px;
        text-align: center;
        color: #fff;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
        outline: none;
        box-shadow: none;
        cursor: pointer;
      }
    }

    .filterbutton {
      button {
        margin: 0px;
        padding: 0px;
        border: none;

        img {
          width: 48px;
        }
      }
    }

    .mapbutton {
      button {
        border-radius: 5px;
        border: 1.5px solid #d7d7d7;
        box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
        height: 48px;
        width: 48px;
      }
    }
  }
}

.Filternavbar {
  width: 630px !important;
  border: 1px solid rgba(26, 25, 25, 0.1);
  background: #fafafa;
  box-shadow: -20px 4px 200px 0px rgba(0, 0, 0, 0.15);

  .offcanvas-header {
    justify-content: center;
    position: relative;
    margin-bottom: 33px;
    padding-bottom: 23px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    padding-top: 40px;

    h5 {
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.22px;
    }

    .restbittn {
      position: absolute;
      right: 33px;
      color: #4b2763;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background-color: transparent;
      border: none;
    }
  }

  .creditcardbuton {
    border-radius: 8px;
    background: #4b2763;
    width: 289px;
    height: 55px;
    color: #fff;
    text-align: center;
    font-family: "Lexend";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.15px;
    outline: none;
    box-shadow: none;
    border: none;
    margin: 0 auto;
    margin-top: 70px;
  }

  .offcanvas-body {
    padding-left: 39px;
    padding-right: 39px;
    display: flex;
    flex-direction: column;

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }

    .css-1rv8zyt-MuiStack-root > :not(style):not(style) {
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
      margin-right: 2px;
    }

    .dateform {
      margin-bottom: 20px;
      margin-top: 0px;
    }

    .timepicker {
      margin-bottom: 27px;
      margin-top: 0px;
    }

    .firstfilter {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .form-check {
        margin-right: 70px;
        margin-bottom: 0px;

        .form-check-input:checked {
          background-color: #fff;
          border-color: #4b2763;
          border: 4px solid #4b2763;
        }

        .form-check-input {
          background-color: #d9d9d9;
          margin-top: 6px;
          border-color: #d9d9d9;
          box-shadow: none !important;
        }

        label {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
        }
      }
    }

    .llllllllllll {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .asdasfasfasf {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      h4 {
        color: #000;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 25px;
        margin-bottom: 18px;
      }
    }

    .downallinputs {
      .locationsmain {
        position: relative;
        margin-bottom: 30px;

        img {
          position: absolute;
          right: 27px;
          top: 21px;
        }

        input {
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
          padding: 24px;
          outline: none;
          box-shadow: none;
          width: 100%;
        }
      }

      .searchmain {
        position: relative;
        margin-top: 40px;

        img {
          position: absolute;
          right: 27px;
          top: 21px;
        }

        input {
          margin-bottom: 20px;
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
          padding: 24px;
          outline: none;
          box-shadow: none;
          width: 100%;
        }
      }
    }
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.inner-22 .css-1rv8zyt-MuiStack-root {
  padding-top: 0px !important;
}

// @media (max-width: 1680px) {}
.detailmodal.modaltimmer .modal-dialog {
  max-width: 515px !important;
}
.detailmodal.modaltimmer .modal-content {
  padding: 8px 0px !important;
}
.modaltimmer {
  .css-1aj9me-MuiPickersLayout-root {
    justify-content: center !important;
  }

  // .css-d0vs79-MuiClockPointer-root{
  //     background-color: #4B2763;
  // }
  // .css-rdq5h4-MuiClockPointer-root{
  //     background-color: #4B2763;
  // }

  // .css-eg3pzz-MuiClockPointer-thumb{
  //     border: 16px solid #4B2763;
  //     background-color: #4B2763;
  // }
  // .css-12t0dn4-MuiClockPointer-thumb{
  //     background-color: #4B2763;
  //     border: 16px solid #4B2763;
  // }
  // .css-umzx0k-MuiClock-pin{
  //     background-color: #4B2763;
  // }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
    background: #4b2763;
    color: #fff;
  }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    border-radius: 8px;
    background: #4b2763;
    height: 55px;
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.15px;
    padding: 22px 20px;
    outline: none;
    box-shadow: none;
    border: none;
  }

  .css-1hbyad5-MuiTypography-root {
    font-size: 18px !important;
    font-weight: 600;
  }

  .css-1r3tc0d-MuiTimePickerToolbar-hourMinuteLabel {
    margin-top: 33px !important;
  }

  .css-1m5r8g7-MuiTimePickerToolbar-amPmSelection {
    margin-top: -42px !important;
  }

  .css-1aj9me-MuiPickersLayout-root .MuiPickersLayout-actionBar {
    justify-content: center !important;
  }

  // .css-1j9v0by-MuiClock-root{
  //     justify-content: flex-end!important;
  // }
  .css-2x8kvt-MuiPickersArrowSwitcher-root-MuiTimeClock-arrowSwitcher {
    right: -11px !important;
    top: -3px !important;
  }

  .css-ku1d3k-MuiTimeClock-root {
    width: 247px !important;
  }
}

@media (max-width: 1500px) {
  .Navbar-new .navbar-light .navbar-nav .nav-link {
    font-size: 18px !important;
    margin-right: 40px !important;
  }

  .Navbar-new .left-side-logo h5 {
    font-size: 20px !important;
  }

  .Navbar-new-filter .outermain-filter .buttonfind button {
    width: 140px !important;
    height: 49px !important;
    text-align: center;
    color: #fff;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }

  .Navbar-new .navbar {
    padding-top: 14px;
    background: #fafafa;
    padding-bottom: 20px;
  }

  .Navbar-new-filter {
    background: #ebebeb;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .Navbar-new-filter .outermain-filter .left-side-main .inner-11 {
    padding: 10px 16px;
  }

  .Navbar-new-filter .outermain-filter .left-side-main .inner-22 {
    padding: 0px 16px;
  }

  .Navbar-new-filter .outermain-filter .left-side-main .ooooo input {
    font-size: 14px !important;
  }

  .Navbar-new-filter .outermain-filter .buttonfind button {
    height: 44px !important;
  }

  .Navbar-new-filter .outermain-filter .filterbutton button img {
    height: 43px !important;
  }
}

.Login,
.additional-notes {
  .modal-content {
    border-radius: 44px;
    background: #fff;
    padding: 72px;
  }

  .modal-dialog {
    max-width: 700px;
  }

  .centerdiv_right {
    .button-s {
      color: #fff;
      text-align: center;
      font-family: "Lexend";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      letter-spacing: 0.25px;
      border-radius: 8px;
      background: #4b2763;
      height: 55px;
      box-shadow: none;
      border: none;
      width: 100%;
      margin-top: 36px;
    }

    h2 {
      color: #151515;
      font-family: "Poppins";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 43px;
      letter-spacing: -0.8px;
      padding-bottom: 19px;
      margin: 0px;
    }

    h4 {
      color: #807e7e;
      font-family: "Poppins";
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      margin: 0px;
      padding-bottom: 55px;
    }

    .outerinnerfeild {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      input {
        border-radius: 10px;
        background: #f6f6f6;
        color: #060505;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        border: none;
        font-weight: 400;
        line-height: 16px;
        padding: 22px 28px;
        width: 100%;
        outline: none;
        box-shadow: none;
      }

      label {
        color: #060505;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.18px;
        padding-bottom: 13px;
      }
    }
  }
}

.css-d0vs79-MuiClockPointer-root {
  background-color: #4b2763 !important;
}
.css-12t0dn4-MuiClockPointer-thumb {
  background-color: #4b2763 !important;
  border: 16px solid #4b2763 !important;
}
.css-umzx0k-MuiClock-pin {
  background-color: #4b2763 !important;
}
.css-rdq5h4-MuiClockPointer-root {
  background-color: #4b2763 !important;
}
.css-eg3pzz-MuiClockPointer-thumb {
  border: 16px solid #4b2763 !important;
  background-color: #4b2763 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  border-radius: 8px !important;
  background: #4b2763 !important;
  height: 55px !important;
  color: #fff !important;
  text-align: center !important;
  font-family: "Poppins" !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 33px !important;
  letter-spacing: 0.15px !important;
  padding: 22px 20px !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.css-13u1oz-MuiButtonBase-root-MuiButton-root-MuiPickersToolbarButton-root {
  // background-color: #4B2763!important;
  color: #4b2763 !important;
  font-size: 24px !important;
}
.css-147rllg {
  p {
    display: none !important;
  }
}
.css-147rllg {
  padding: 0px !important;
}
.css-165mck1 {
  margin: 0px !important;
}
.css-1ob76hp {
  margin-top: 0px !important;
}
.MuiStack-root.css-11a8txn-MuiStack-root {
  p {
    display: none !important;
  }
}
.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  margin-top: 0px !important;
}

.css-7lip4c {
  background-color: #4b2763 !important;
}

.css-12ha4i7 {
  background-color: #4b2763 !important;
}

.css-118whkv {
  background-color: #4b2763 !important;
  border: 16px solid #4b2763 !important;
}

.css-2ujp1m {
  border: 16px solid #4b2763 !important;
}

.css-1ujsas3 {
  border-radius: 8px !important;
  background: #4b2763 !important;
  height: 55px !important;
  color: #fff !important;
  text-align: center !important;
  font-family: "Poppins" !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 33px !important;
  letter-spacing: 0.15px !important;
  padding: 22px 20px !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.css-a1rc6s {
  background-color: #4b2763 !important;
}

.css-1vskg8q {
  justify-content: center !important;
}

.css-1bzq5ag {
  font-size: 18px !important;
  font-weight: 600 !important;
}
.us-ic {
  width: 45px;
  height: 45px;
}
.c-p {
  cursor: pointer;
}

.buttonforbasket {
  display: none;
}

.sdasdasdwgerbe {
  text-decoration: none;
}
.d-n {
  display: none;
}
