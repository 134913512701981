.StoreDetail {
  .pppppp {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
    // min-width: 300px;
    // max-width: 300px;

    @media only screen and (min-width: 1509px) {
      min-width: 300px;
      max-width: 300px;
    }

    @media only screen and (max-width: 1680px) {
      min-width: 345px;
      max-width: 345px;
    }

    @media only screen and (max-width: 1500px) {
      min-width: 250px;
      max-width: 250px;
    }

    @media only screen and (max-width: 1400px) {
      min-width: 305px;
      max-width: 305px;
    }

    @media only screen and (max-width: 1300px) {
      min-width: 292px;
      max-width: 292px;
    }

    @media only screen and (max-width: 1200px) {
      min-width: 277px;
      max-width: 277px;
    }

    @media only screen and (max-width: 1100px) {
      min-width: 257px;
      max-width: 257px;
    }

    @media only screen and (max-width: 1050px) {
      min-width: 316px;
      max-width: 316px;
    }

    @media only screen and (max-width: 900px) {
      min-width: 100%;
      max-width: 100%;
    }

    // @media only screen and (max-width: 1086.4px) {
    //   min-width: 343px;
    // }
  }

  .outermainnewone {
    margin-top: 20px;

    .clockss {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;

      h6 {
        color: #6b7280;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 13.37px;
      }
    }
  }

  .cardmain {
    .rectangle_shape {
      position: absolute;
      bottom: 0px;
      width: 100%;
      // height: 35px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: rgb(0 0 0 / 70%);
      // opacity: 0.5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .upperheading {
        h3 {
          color: #fff;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.12px;
          padding-right: 15px;
        }
      }
      .rirr {
        h5 {
          color: #fff;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.12px;
          padding-left: 15px;
        }
      }
    }
    .upperheading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ratings {
      i {
        color: #cecece !important;
      }

      .rating-color {
        color: #ffa417 !important;
      }
    }

    position: relative;
    // height: 259px;
    border-radius: 17px;
    margin-right: 0px;
    background: #fff;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

    .uppercard {
      height: 160px;
      position: relative;

      .cnctwltimg {
        height: 160px;
        width: 100%;
        object-fit: cover;
        border-radius: 17px 17px 0px 0px;
      }

      .mmmmmmm {
        position: absolute;
        border-radius: 17px 17px 0px 0px;
        top: 0;
        height: 104px;
        width: 100%;
        object-fit: cover;
      }
    }

    .lowercard {
      padding: 11px;
      padding-bottom: 20px;

      .slotshere {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .design {
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid rgba(119, 56, 162, 0.6);
          background: #edf0ff;
          // padding: 5.5px 6px;
          width: 59.803px;
          height: 28.575px;
          justify-content: center;
          align-items: center;
          display: flex;

          &:hover {
            background-color: #4b2763;

            h5 {
              color: #fff;
            }
          }

          h5 {
            color: #4b2763;
            text-align: center;
            font-family: "Poppins";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      h4 {
        color: #2e2b2b;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        width: 158px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }

      h3 {
        color: #000;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      .outrermainmain {
        padding-bottom: 15px;
        margin-bottom: 19px;
        border-bottom: 2px dotted rgba(182, 182, 182, 0.4588235294);
        margin-top: 11px;

        h6 {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-top: 12px;
        }

        .main-outer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .rightside {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ppppp {
              width: 6.87px;
              height: 6px;
              border-radius: 50%;
              background-color: #2e2b2b;
              margin-right: 9px;
            }

            .rirr {
              h5 {
                color: #707070;
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
              }
            }
          }

          .left-side {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .text {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-top: 3px;
              margin-left: 5px;

              h4 {
                color: rgba(0, 0, 0, 0.6) !important;
                font-family: "Poppins" !important;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 17px !important;
                padding-bottom: 0px !important;
              }

              h5 {
                color: rgba(0, 0, 0, 0.6);
                font-family: "Poppins";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
              }
            }
          }
        }
      }
    }
  }

  .padding-child {
    padding-right: 0px;
  }

  .padding-main {
    padding: 0px;
  }

  .smokeshowstudio {
    border-radius: 20px;
    border: 3px solid #f3f4f6;
    background: #fff;
    padding: 24px;
    padding-right: 50px;

    .upperrow {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 52px;
      margin-bottom: 22px;
      border-bottom: 2px dotted#dddddd;

      .leftsidesmoke {
        .outmaininner {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .leftsidemaininner {
            width: 120px;
            height: 120px;
            border-radius: 9px;
            margin-right: 25px;

            .sasdasdasdzxewfsac {
              width: 120px;
              height: 120px;
              border-radius: 9px;
              object-fit: cover;
            }
          }

          .rightsidemaininner {
            h3 {
              color: #2e2b2b;
              font-family: "Poppins";
              font-size: 38px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
              margin-bottom: 17.67px;
            }

            .ratings {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .downright {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                h6 {
                  color: rgba(0, 0, 0, 0.6);
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 21px;
                }
              }

              .upperright {
                display: flex;
                justify-content: flex-start;
                align-items: center;
              }

              .text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 126px;

                h4 {
                  color: rgba(0, 0, 0, 0.6) !important;
                  font-family: "Poppins" !important;
                  font-size: 16px !important;
                  font-style: normal !important;
                  font-weight: 400 !important;
                  line-height: 17px !important;
                  padding-bottom: 0px !important;
                  margin-right: 3px;
                }

                h5 {
                  color: rgba(0, 0, 0, 0.6);
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 17px;
                }
              }

              .ratings {
                margin-right: 10px;
              }

              .ratings i {
                color: #cecece;
                font-size: 32px;
              }

              .rating-color {
                color: #fbc634 !important;
              }

              .review-count {
                font-weight: 400;
                margin-bottom: 2px;
                font-size: 24px !important;
              }

              .small-ratings i {
                color: #cecece;
              }

              .review-stat {
                font-weight: 300;
                font-size: 18px;
                margin-bottom: 2px;
              }
            }

            .cardssiner {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 17.67px;

              .innerrs {
                border-radius: 8px;
                background: #edf0ff;
                margin-right: 10.5px;

                h5 {
                  color: #7738a2;
                  text-align: center;
                  font-family: "Lexend";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 8px 16px;
                }
              }

              .innerrss {
                border-radius: 8px;
                border: 1px solid #d5e5d8;
                background: #edfff0;
                margin-right: 10.5px;

                h5 {
                  color: #236512;
                  text-align: center;
                  font-family: "Lexend";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 8px 16px;
                }
              }

              .innerrsss {
                border-radius: 8px;
                border: 1px solid #ffdbfc;
                background: #ffeaff;

                h5 {
                  color: #c33cb6;
                  text-align: center;
                  font-family: "Lexend";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 8px 16px;
                }
              }
            }
          }
        }
      }

      .rightsidesmoke {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 24px;

        .storeimsdfdsfsdfageleft {
          width: 33.7px;
          height: 33.7px;
          border-radius: 50%;
          margin-right: 12.3px;
          object-fit: cover;
        }

        h6 {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;

          span {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
          }
        }
      }
    }

    .downrow {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .left-side-main {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 6px 6px 6px 6px;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

        .ooooo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          input {
            border: none;
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            box-shadow: none;
            outline: none;

            ::placeholder {
              color: rgba(0, 0, 0, 0.5);
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .inner-11 {
          border-right: 2px solid rgba(0, 0, 0, 0.1);

          .react-datepicker-ignore-onclickoutside {
            cursor: pointer;
          }

          cursor: pointer;
          position: relative;

          .cnctwltimg {
            position: absolute;
            right: 15px;
            pointer-events: none;
            cursor: pointer;
          }

          width: 200px;

          input {
            width: 164px;
          }

          padding: 13px 16px;
        }

        .inner-22 {
          border-right: none;
          position: relative;

          .cnctwltimsdchjudsbcg {
            position: absolute;
            right: 16px;
            cursor: pointer;
            pointer-events: none;
          }

          input {
            pointer-events: none;
          }

          //   input[type="text"] {
          //     pointer-events: initial;
          //   }
          input {
            width: 142px;
          }

          width: 200px;
          padding: 0px 16px;
          height: 50px;
        }

        .inner-33 {
          width: 313px;
          padding: 13px 16px;
        }
      }

      .alltimess {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h6 {
          color: rgba(0, 0, 0, 0.6);
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 10px;
          margin-right: 60px;
        }
      }
    }
  }

  .Specialistss {
    h2 {
      color: #000;
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 17.5px;
    }

    .outmainminnerspecilist {
      border-radius: 10px;
      background: #fff;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      // margin-bottom: 22px;

      .buttonviewavailablity {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #edf0ff;
        width: 100%;
        color: #4b2763;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
        outline: none;
        box-shadow: none;
        height: 35px;

        img {
          padding-right: 14px;
        }
      }

      .outmaininnerspecia {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-bottom: 14px;
        // margin-bottom: 14px;
        // border-bottom: 2px dotted#dddddd;

        .leftsidem {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            object-fit: cover;
          }

          .innerrightsidespec {
            margin-left: 14px;

            h3 {
              color: #000;
              font-family: "Poppins";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              // margin-bottom: 10px;
            }

            h5 {
              color: rgba(3, 3, 3, 0.7);
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .rightsidem {
          border-radius: 12.5px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          height: 25px;
          width: 50px;

          .upperright {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h4 {
              color: rgba(0, 0, 0, 0.6) !important;
              font-family: "Poppins" !important;
              font-size: 12px !important;
              font-style: normal !important;
              font-weight: 400 !important;
              line-height: 17px !important;
              padding-bottom: 0px !important;
              padding-top: 1px;
            }

            h5 {
              color: rgba(0, 0, 0, 0.6);
              font-family: "Poppins";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 17px;
            }
          }

          .ratings {
            margin-right: 10px;
          }

          .ratings i {
            margin-top: -2px !important;
            color: #cecece;
            font-size: 32px;
          }

          .rating-color {
            color: #fbc634 !important;
          }

          .review-count {
            font-weight: 400;
            margin-bottom: 2px;
            font-size: 24px !important;
          }

          .small-ratings i {
            color: #cecece;
          }

          .review-stat {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .gallery {
    margin-top: 42px;
    margin-bottom: 20px;

    .galleryimages.llliii {
      margin-bottom: 22px;
      height: 429px;

      img {
        border-radius: 20px;
        width: 100%;
        height: 429px;
        object-fit: cover;
      }
    }

    .galleryimages.iiiooo {
      margin-bottom: 12px;
      height: 208px;

      img {
        border-radius: 20px;
        width: 100%;
        height: 208px;
        object-fit: cover;
      }
    }
  }

  .aboutsmoke {
    margin-bottom: 88px;
    // margin-top: 50px;
    border-radius: 20px;
    border: 3px solid #f3f4f6;
    background: #fff;
    padding: 30px;

    p {
      color: #000;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin-top: 30px;
    }

    .main-upper {
      padding-bottom: 18px;
      margin-bottom: 32px;
      border-bottom: 2px dotted#dddddd;

      h3 {
        color: #000;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .outermainabout {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-side-about {
        // width: 50px;
        // height: 50px;
        // border-radius: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 100px;
          margin-right: 14px;
          height: 100px;
          border-radius: 50%;
        }

        .text {
          h5 {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            // margin-bottom: 8px;
          }

          h6 {
            color: #808285;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 17px;
          }
        }
      }

      .right-side-about {
        a {
          color: #808285;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          text-decoration-line: underline;
        }
      }
    }
  }
}

.StoreDetail
  .Specialistss
  .outmainminnerspecilist
  .outmaininnerspecia
  .rightsidem
  .ratings
  i {
  margin-right: 5.5px;
  font-size: 12px !important;
}

.StoreDetail
  .Specialistss
  .outmainminnerspecilist
  .outmaininnerspecia
  .rightsidem
  .rating-color {
  color: #ffa417 !important;
}

.assdasdasasdasd {
  width: 30px !important;
  margin-left: 10px;
}

// .StoreDetail .aboutsmoke .outermainabout .left-side-about .text h6{
//     margin-top: -8px;
// }

.StoreDetail
  .Specialistss
  .outmainminnerspecilist
  .outmaininnerspecia
  .rightsidem
  .ratings {
  margin-right: 0px;
  margin-top: -2px;
}

.StoreDetail
  .smokeshowstudio
  .upperrow
  .leftsidesmoke
  .outmaininner
  .rightsidemaininner
  .ratings
  .ratings
  i {
  margin-right: 2px;
  font-size: 15px !important;
}

.StoreDetail
  .smokeshowstudio
  .upperrow
  .leftsidesmoke
  .outmaininner
  .rightsidemaininner
  .ratings
  .rating-color {
  color: #ffa417 !important;
}

.StoreDetail
  .smokeshowstudio
  .upperrow
  .leftsidesmoke
  .outmaininner
  .rightsidemaininner
  .ratings {
  margin-right: 0px;
}

.FrontMain_Stores.serviceslider .caresousaldownbaloon {
  margin-bottom: 20px !important;
}

.serviceslider {
  .main_upper_bar {
    margin-bottom: 34px;

    .rightside {
      position: relative;

      input {
        border-radius: 6px;
        border: 1px solid #e5e5e5;
        background: #fff;
        color: #808080;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
        box-shadow: none;
        width: 309px;
        height: 40px;
        padding-left: 50px;
      }

      img {
        position: absolute;
        left: 17px;
        top: 11px;
      }
    }
  }
}
.kokokokoko {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
}
.detailmodalspecialist {
  .outerdivspeciallist {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 56px;
    flex-wrap: wrap;
  }

  .modal-dialog {
    max-width: 762px;
  }

  .modal-content {
    border-radius: 17px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }

  .modal-body {
    padding-top: 0px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .arrowimg {
    .speciallist {
      text-align: center;
      padding-top: 19px;
      padding-bottom: 13px;
      margin-bottom: 30px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);

      h6 {
        color: #000;
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .innerprofile {
    width: 85px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 132px;
    overflow: visible;
    opacity: 1;
    transition: 0.4s linear;
    // position: relative;

    .upperprofile {
      width: 55px;
      height: 55px;
      object-fit: cover;
      border-radius: 50%;
      position: relative;
      margin: 0 auto;

      .calenderimg {
        position: absolute;
        right: -5px;
        top: -3px;
        pointer-events: fill !important;
      }

      .profileimg {
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 50%;
      }

      .rightsidem {
        position: absolute;
        bottom: 0px;
        left: -1px;
        border-radius: 12.5px;
        background: #fff;
        height: 13px;
        width: 28px;
        border-radius: 6.561px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;

        .upperright {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .text {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          h4 {
            color: rgba(0, 0, 0, 0.6) !important;
            font-family: "Poppins" !important;
            font-size: 9px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 17px !important;
            padding-bottom: 0px !important;
            margin-top: -2px !important;
          }

          h5 {
            color: rgba(0, 0, 0, 0.6);
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
          }
        }

        .ratings {
          margin-right: 10px;
        }

        .ratings i {
          margin-top: -2px !important;
          color: #cecece;
          font-size: 32px;
        }

        .rating-color {
          color: #fbc634 !important;
        }

        .review-count {
          font-weight: 400;
          margin-bottom: 2px;
          font-size: 24px !important;
        }

        .small-ratings i {
          color: #cecece;
        }

        .review-stat {
          font-weight: 300;
          font-size: 18px;
          margin-bottom: 2px;
        }
      }
    }

    .lowerside {
      .maintool {
        position: relative;
        cursor: pointer;
        &:hover .tooltiptext {
          display: block;
        }

        .tooltiptext {
          font-family: "Poppins";
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px;
          padding: 2px 8px;
          border-radius: 4px;
          position: absolute;
          top: -23px;
          right: -26px;
          background: #000;
          color: white;
          display: none;
        }
      }
      .outmainh5 {
        cursor: pointer;
        position: relative;

        &:hover .mainouterlocation {
          display: flex !important;
        }
      }

      .mainouterlocation {
        position: absolute;
        bottom: -72px;
        border-radius: 3px;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px;
        width: 220px;
        display: none;
        z-index: 1111111;

        img {
          width: 14.4px;
          height: 14.4px;
          margin-top: 1px;
        }

        .text {
          margin-left: 5px;
        }

        p {
          color: rgba(3, 3, 3, 0.7);
          font-family: "Poppins";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: start !important;
        }

        h6 {
          color: rgba(3, 3, 3, 0.7);
          font-family: "Poppins";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          text-align: start;
          line-height: normal;
        }
      }

      h4 {
        color: #000;
        font-family: "Lexend";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-top: 8px;
      }

      h5 {
        color: rgba(3, 3, 3, 0.7);
        text-align: center;
        font-family: "Lexend";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-decoration-line: underline;
      }

      button {
        border-radius: 5px;
        border: 1px solid rgba(119, 56, 162, 0.6);
        background: #edf0ff;
        margin-top: 9px;
        color: #4b2763;
        text-align: center;
        font-family: "Lexend";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        height: 25px;
        outline: none;
        box-shadow: none;
        width: 70px;

        &:hover {
          background-color: #4b2763;
          color: #fff;
        }
      }
    }
  }
}

.textssss {
  // text-decoration: none!important;
  color: #808285;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-decoration-line: underline;
}

@media (max-width: 1500px) {
  .StoreDetail .cardmain .lowercard .slotshere .design {
    // padding: 5.5px 4px !important;
  }
  .StoreDetail .cardmain .lowercard .slotshere .design h5 {
    color: #4b2763;
    text-align: center;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 3px;
  }
}
.d-n {
  display: none;
}
.m-b-150 {
  margin-bottom: 150px;
}

.serviceslider .cardmain .upperheading {
  margin-bottom: 21px;
  margin-top: 8px;
}

.serviceslider .cardmain .lowercard h3 {
  font-size: 15px !important;
  font-weight: 700;
}
.serviceslider {
  .rirr {
    h5 {
      color: #000;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1px;
      font-size: 16px;
    }
  }

  .rectangle_shape h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.mainoutermasin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .buttonfind {
    margin-left: 20px;
    button {
      border-radius: 8px;
      background: #4b2763;
      width: 157px;
      height: 52px;
      text-align: center;
      color: #fff;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }
}

.storedetailpagefor {
  .Navbar-new-filter {
    .outermain-filter {
      display: none;
    }
  }
}

.detailmodalspecialist .innerprofile .lowerside h4 {
  width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.incart {
  background-color: #236512 !important;
  // color: #cecece !important;
  color: white !important;
  pointer-events: none;
}

.unavailable {
  background-color: #dadada !important;
  color: #707070 !important;
  pointer-events: none;
}

.filter-nav {
  background: transparent !important;
  border: unset !important;
  margin-top: 40px;
}
.addtocart {
  width: 100%;
}
