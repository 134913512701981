.main-cloud{
    // padding-top: 112px;
    padding-bottom: 131px;
.banner_nav {
    width: 100%;
    background: #fafafa;
    button.btnnav {
        background: #fafafa;
        border: 1px solid #ffe1f0;
        border-radius: 8px;
        padding: 10px 18px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #4B2763;
        margin-top: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 44px;
    }
    .mainheading {
        font-style: normal !important;
        font-family: 'Poppins' !important;
        font-size: 40px !important;
        line-height: 110%;
        margin-bottom: 55px;
    }
}
.sdsddsx{
    text-decoration: none!important;
}
.btnnav{
    text-decoration: none!important;
    p{
        padding-left: 10px;
    }
}
.fistpara {
    margin-top: 30px;
    .paraheading {
        font-style: normal;
        font-family: 'Poppins';
        font-size: 16px !important;
        line-height: 130%;
    }
    .fstparaa {
        font-style: normal;
        font-family: 'Poppins';
        font-size: 16px !important;
        line-height: 130%;
        margin-top: 50px;
        color: #484848;
    }
}
.secpara {
    margin-top: 50px;
    .secparaa{
        font-style: normal;
       font-family: 'Poppins';
        font-size: 16px !important;
        line-height: 130%;
        color: #484848;
    }
    .paraheading {
        font-style: normal;
        font-family: 'Poppins';
        font-size: 22px !important;
        line-height: 110%;
        color: #000000;
        margin-bottom: 30px;
        border-bottom: 1.5px solid #4B2763;
        // width: max-content;
        padding-bottom: 13px;
    }
    .seceendppaarra {
        font-style: normal;
        font-family: 'Poppins';
        font-size: 16px !important;
        line-height: 130%;
        color: #484848;
    }
    .thrrddppaarra {
        font-family: system-ui;
        font-style: normal;
        font-family: 'Poppins';
        font-size: 16px !important;
        line-height: 110%;
        margin-bottom: 20px;
    }
    .startingggeess {
        margin-top: 20px;
        display: flex;
        align-items: baseline;
        .twooooo {
            font-style: normal;
           font-family: 'Poppins';
            font-size: 16px !important;
            line-height: 130%;
            margin-right: 20px;
        }
        .startingggeess.Allow {
            margin-left: 51px;
            .inlinestyle {
                margin-left: 80px;
                margin-top: 15px;
                .dootli {
                    margin-top: 15px;
                    font-style: normal;
                   font-family: 'Poppins';
                    font-size: 16px !important;
                    line-height: 130%;
                }
            }
        }
    }
}
.p {
    font-style: normal !important;
    font-family: 'Poppins' !important;
    font-size: 16px !important;
    line-height: 130% !important;
    color: #484848 !important;
  }
  .ppppppaaaaaaaarrrraaaaaaa{
    font-style: normal !important;
    font-family: 'Poppins' !important;
    font-size: 16px !important;
    line-height: 130% !important;
    color: #484848 !important;
  }
  li{
    font-style: normal !important;
    font-family: 'Poppins' !important;
    font-size: 16px !important;
    line-height: 130% !important;
    color: #484848 !important;
    list-style-type: disc;
  }
  .parahading{
    font-style: normal;
    font-family: 'Poppins';
    font-size: 22px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000000;
  }

}

.main-cloud .secpara .thrrddppaarra{
    margin-bottom: 3px!important;
}

.main-cloud .secpara .secparaa{
    margin-bottom: 20px!important;
}

@media (max-width: 600px) {
    .main-cloud .banner_nav .mainheading{
        font-size: 30px!important;
    }
    .main-cloud .secpara .paraheading{
        font-size: 18px!important;
    }
}
